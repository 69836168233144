const Config = {
  // apiUrl: "http://128.199.139.224:5556/",
  apiUrl: "https://questiondashboardapi.thenobocompany.com/",
  // apiUrl: "http://167.99.73.174:3003/",
  // apiUrl: "http://192.168.1.21:3002/",
  encryptionKey: "C&F)J@NcQfTjWnZr4u7x!A%D*G-KaPdS",
  limitPage: 10,
  encryptionEnable: false,
};
export const encryptionEnable = Config.encryptionEnable;
export default Config;
