import Config from "../config/config";
import { Tracing } from "trace_events";
import axiosInstance from "./axiosInstance";

let url: string = "payout";
let url1: string = "payoutStructure";
const getAll = () => {
  return axiosInstance.get(Config.apiUrl + url);
};
const getAllPAyoutStructure = () => {
  return axiosInstance.get(Config.apiUrl + url1);
};
const create = (data: any) => {
  return axiosInstance.post(Config.apiUrl + url, data);
};
const createPayoutStructure = (data: any) => {
  return axiosInstance.post(Config.apiUrl + url1, data);
};
const view = (id: any) => {
  return axiosInstance.get(Config.apiUrl + url + "/viewPayoutStructure/" + id);
};
const update = (data: any, id: string | undefined) => {
  return axiosInstance.post(Config.apiUrl + url + "/" + id, data);
};
const updatePayoutStructure = (data: any, id: string | undefined) => {
  return axiosInstance.post(Config.apiUrl + url1 + "/" + id, data);
};
const getOne = (id: string | undefined) => {
  return axiosInstance.get(Config.apiUrl + url + "/" + id);
};
const getOnePayoutView = (id: string | undefined) => {
  return axiosInstance.get(Config.apiUrl + url1 + "/" + id);
};
const deleteOne = (id: string) => {
  return axiosInstance.delete(Config.apiUrl + url + "/" + id);
};
const deleteOnePayoutStructure = (id: string) => {
  return axiosInstance.delete(Config.apiUrl + url1 + "/" + id);
};
const getOneEditPayout = (id: string | undefined) => {
  return axiosInstance.get(Config.apiUrl + url1 + "/" + id);
};
const duplicatePayoutStructure = (name: string, id: string | undefined) => {
  return axiosInstance.post(Config.apiUrl + url + "/duplicate/" + id, name);
};
const payoutStructure = {
  create,
  getAllPAyoutStructure,
  getOneEditPayout,
  createPayoutStructure,
  getAll,
  getOne,
  getOnePayoutView,
  deleteOne,
  deleteOnePayoutStructure,
  updatePayoutStructure,
  update,
  view,
  duplicatePayoutStructure,
};
export default payoutStructure;
