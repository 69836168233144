import { Link } from "react-router-dom";
import "./breadcrumbs.css";

interface BreadCrumbsType {
  parentItem?: string;
  parentLink?: string;
  childItem: string;
  childLink?: string;
}
const BreadCrumbs = ({
  parentItem = "Dashboard",
  parentLink = "dashboard",
  childItem,
}: BreadCrumbsType) => {
  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs-list flex-wrap">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={"/" + parentLink}>{parentItem}</Link>
        </li>
        <li className="breadcrumb-item">
          <span>{childItem}</span>
        </li>
      </ul>
    </div>
  );
};

export default BreadCrumbs;
