import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./layout/AuthLayout/AuthLayout";
import DashboardLayout from "./layout/DashboardLayout";

import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";

import Dashboard from "./pages/DashBoard";

import CreateTournament from "./pages/Tournament/CreateTournament";
import EditTournament from "./pages/Tournament/EditTournament";
import ListTournament from "./pages/Tournament/ListTournament";
import ViewTournament from "./pages/Tournament/ViewTournament";
import DuplicateTournament from "./pages/Tournament/DuplicateTournament";
import TournamentReport from "./pages/MinTournament/Report/TournamentReport";
import TournamentDetail from "./pages/MinTournament/Report/TourmamentDetail/TournamentDetail";
import MiniManagement from "./pages/MinTournament/Management/MiniManagement";
import ListBots from "./pages/BotsManagement/ListBots/ListBots";
import ListQuestionManagement from "./pages/QuestionManagement/ListQuestionManagement";
import ViewQuesetion from "./pages/QuestionManagement/ViewQuesetion";
import ListMCQQuestionManagement from "./pages/MCQQuestionManagement/ListMCQQuestionManagement";
import ViewMCQQuestion from "./pages/MCQQuestionManagement/ViewQuesetion/ViewMCQQuestion";
import EditMCQQuestion from "./pages/MCQQuestionManagement/EditQuesetion/EditMCQQuestion";
function App() {
  const token = localStorage.getItem("access_token");
  return (
    <BrowserRouter>
      <Routes>
        {/* LAYOUT DASHBOARD */}
        {/* <Route path="*" element={token ? <DashboardLayout /> : <Login />} /> */}
        {token ? (
          <Route path="/" element={<DashboardLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* TOURNAMENT MANAGEMENT */}
            <Route path="/createTournament" element={<CreateTournament />} />
            <Route path="/editTournament/:id" element={<EditTournament />} />
            <Route path="/viewTournament/:id" element={<ViewTournament />} />
            <Route path="/listTournament" element={<ListTournament />} />
            <Route
              path="/minTournament"
              element={<MiniManagement />}
            />
            {/* GUESS QUESTION MANAGEMENT */}
            <Route path="/questionManagement/" element={<ListQuestionManagement />} />
            <Route path="/viewGuesstion/:id" element={<ViewQuesetion />} />
            {/* MCQ QUESTION MANAGEMENT */}
            <Route path="/mcqQuestionManagement/" element={<ListMCQQuestionManagement />} />
            <Route path="/viewMCQ/:id" element={<ViewMCQQuestion />} />
            <Route path="/editMCQ/:id" element={<EditMCQQuestion />} />

            {/* TOURNAMENT REPORT */}

            <Route path="/reportTournament" element={<TournamentReport />} />
            <Route path="/detailTournament/:id" element={<TournamentDetail />} />
          </Route>
        ) : (
          <Route path="/" element={<AuthLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route index element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
