import Config from "../config/config";
import axiosInstance from "./axiosInstance";

let url: string = "dashboard/api/";

const getListGuessTion = ({ page, limit, ...data }: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `guessquestion/list?page=${page}&limit=${limit || Config.limitPage}`,
        data
    );
};

const getOneGuesstion = (id: string) => {
    return axiosInstance.get(
        Config.apiUrl +
        url +
        `guessquestion/${id}`,
    );
};

const EditGuesstion = (data: any) => {
    return axiosInstance.put(
        Config.apiUrl +
        url +
        `guessquestion/update/${data.id}`, data
    );
}

const putFileXLSX = (data: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `guessquestion/upload/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
}

const getListSubjectType = () => {
    return axiosInstance.get(
        Config.apiUrl +
        url +
        `questionsubject/list`,
    );
};

const getListExportData = (data: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `guessquestion/exportallguessquestions`, data,
    );
}

const deleteOneGuesstion = (id: string) => {
    return axiosInstance.delete(
        Config.apiUrl +
        url +
        `guessquestion/delete/${id}`,
    );
}
const deleteGuesstion = () => {
    return axiosInstance.delete(
        Config.apiUrl +
        url +
        `guessquestion/deletes`,
    );
}

const createQuestion = (data: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `guessquestion/add`,
        data
    );
};
const GuessQuestion = {
    getListGuessTion,
    getListSubjectType,
    createQuestion,
    getOneGuesstion,
    EditGuesstion,
    putFileXLSX,
    getListExportData,
    deleteOneGuesstion,
    deleteGuesstion
};

export default GuessQuestion;
