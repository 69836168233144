import Config from "../config/config";
import { MiniTournamentType } from "../utils/types/miniTournamentType";
import axiosInstance from "./axiosInstance";

const url = "dashboard/api/tournamentconfig"
const urlReport = "dashboard/api/report/closed"
const urlDetail = "dashboard/api/detail"

export const getMiniTournament = () => {
    return axiosInstance.get(Config.apiUrl + url);
};

export const createMiniTournament = (data: MiniTournamentType) => {
    return axiosInstance.put(Config.apiUrl + url, data);
};

export const updateMiniTournament = (data: MiniTournamentType, _id: string) => {
    delete data?._id
    return axiosInstance.put(Config.apiUrl + url, data);
}


export const getMiniTournamentReport = () => {
    return axiosInstance.get(Config.apiUrl + urlReport);
};

export const getDetailMiniTournament = (id: string) => {
    return axiosInstance.get(Config.apiUrl + urlDetail + `/${id}`);
}


export const getListGameMode = () => {
    return axiosInstance.get(Config.apiUrl + "dashboard/api/gamemode/list");
}

export const getListMap = () => {
    return axiosInstance.get(Config.apiUrl + "dashboard/api/map/list");
}

export const updateStatusScheduling = (data: any) => {
    return axiosInstance.put(Config.apiUrl + "dashboard/api/tournamentconfigstatus", data)
}