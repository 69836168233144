import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaChevronRight, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthServices from "../../../services/Auth.services";
import successImg from "../../../assets/image/success.png";
import "./forgotPassword.css";

const ForgotPassword = () => {
  const Navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const requestPassword = (email: any) => {
    AuthServices.forgotPassword(email)
      .then((data) => {
        setShowSuccess(true);
      })
      .catch((errors) => {
        toast.error(errors.response.data.message);
      });
  };

  const handleRequest = () => {
    let dataPost = { email: email };
    requestPassword(dataPost);
  };

  const handleBack = () => {
    Navigate("/login");
  };

  //modal
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClose = () => {
    setShowSuccess(false);
    Navigate("/login");
  };

  return (
    <div className="login-page  pb-5">
      <div className="container-login">
        <div className="screen">
          <div className="screen__content">
            <form className="login">
              <div className="forgot-password-title">
                <h1>Forgot your password?</h1>
              </div>
              <div className="forgot-password-letter">
                <h5>
                  Please enter the email you use to sign in to Tournament
                  Dashboard
                </h5>
              </div>
              <div className="login__field">
                <FaUser className="login__icon" />
                <input
                  type="text"
                  className="login__input"
                  placeholder="Enter email address"
                  value={email}
                  onChange={handleChange}
                />
              </div>

              <button
                type="button"
                className="button login__submit"
                onClick={handleRequest}
              >
                <span className="button__text">Request password reset</span>
                <FaChevronRight className="button__icon" />
              </button>
              <div className="back-to-sign-in" onClick={handleBack}>
                <h4>Back to sign in</h4>
              </div>
            </form>
            <div className="social-login">
              <div className="social-icons">
                {/* <img src={logo} alt="logo-img" /> */}
              </div>
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccess}
        onHide={handleClose}
        className="modal-custom-forgotpassword"
      >
        <Modal.Header className="modal-custom-viewPayout-header">
          <img
            className="modal-custom-forgotpassword-icon"
            src={successImg}
            alt="logo-success"
          />
          Success!
        </Modal.Header>
        <Modal.Body className="modal-custom-viewPayout-body">
          Email sent successfully! Please check your email address then Log in
          and reset your Password!!
        </Modal.Body>
        <Modal.Footer className="modal-custom-viewPayout-footer">
          <button className="btn-custom btn-secondary" onClick={handleClose}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
