import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { useQuery } from "@tanstack/react-query";
import { Col, Container, Table } from "react-bootstrap";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GuessQuestion from "../../../services/GuessQuestion.services";
const initialForm = {
  type: "Steady",
  betList: [{ level: 1, ante: 0, bet: 0, lastBet: 0, betAdder: 0, time: 0 }],
};

const ViewQuestion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValue, setFormValue] = useState<any>()
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<any>({
    defaultValues: initialForm,
  });
  const { fields } = useFieldArray<any>({
    control: control,
    name: "betList",
  });

  const betType = useWatch({
    name: "type",
    control,
  });

  const memoBetType: string = useMemo(() => betType, [betType]);

  // QUERY
  const { isLoading } = useQuery(["GET_ONE_BET"], () => GuessQuestion.getOneGuesstion(String(id)), {
    onSuccess: ({ data: res }) => {
      const { data, status } = res;
      if (status) {
        setFormValue(data[0])
      }
    },
    onError: () => {
      toast.error("Connect to server failed.");
    },
  });
  console.log(formValue)
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="section-box pb-5">
          <Col>
            <BreadCrumbs
              childItem="View Question Management"
              parentItem="Question Management"
              parentLink="questionManagement"
            />
            <div className="card m-auto">
              <h3 className="page-title">View Question Management</h3>
              <div className="body-form">
                <form>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="name" className="form-control-label">
                          Difficulty Level
                        </label>
                        <input
                          id="name"
                          type="text"
                          placeholder="Name"
                          className="form-control"
                          {...register("name")}
                          disabled
                          value={formValue?.difficultyLevel}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="name" className="form-control-label">
                          Subject Type
                        </label>
                        <input
                          id="name"
                          type="text"
                          placeholder="Name"
                          className="form-control"
                          {...register("name")}
                          disabled
                          value={formValue?.questionsubjectField[0].name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="description"
                          className="form-control-label"
                        >
                          Question English
                        </label>
                        <textarea
                          id="description"
                          placeholder="Decription"
                          className="form-control"
                          // rows={3}
                          // cols={40}
                          value={formValue?.question}
                          {...register("description")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="description"
                          className="form-control-label"
                        >
                          Question Ar
                        </label>
                        <textarea
                          id="description"
                          placeholder="Decription"
                          className="form-control"
                          // rows={3}
                          // cols={40}
                          value={formValue?.questionAr}
                          {...register("description")}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className=" col-12">
                      <div className="form-group">
                        <label htmlFor="type" className="form-control-label">
                          Player Id
                        </label>
                        <textarea
                          id="description"
                          placeholder="Decription"
                          className="form-control"
                          value={formValue?.reportQuestionData[0].playerId}
                          {...register("description")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className=" col-12">
                      <div className="form-group">
                        <label htmlFor="type" className="form-control-label">
                          Game Session Id
                        </label>
                        <textarea
                          id="description"
                          placeholder="Decription"
                          className="form-control"
                          value={formValue?.reportQuestionData[0].gameSessionId}
                          {...register("description")}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="button-group center-item mt-4">
                    <Button
                      variant="secondary"
                      onClick={() => navigate("/questionManagement")}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};

export default ViewQuestion;
