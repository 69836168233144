import Config from "../config/config";
import axiosInstance from "./axiosInstance";

let url: string = "dashboard/api/";

const getListMCQ = ({ page, limit, ...data }: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `mcquestion/list?page=${page}&limit=${limit || Config.limitPage}`,
        data
    );
};

const getOneMCQ = (id: string) => {
    return axiosInstance.get(
        Config.apiUrl +
        url +
        `mcquestion/${id}`,
    );
};

const EditMCQ = (data: any) => {
    return axiosInstance.put(
        Config.apiUrl +
        url +
        `mcquestion/update/${data.id}`, data
    );
}

const putFileXLSX = (data: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `mcquestion/upload/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
}

const getListSubjectType = () => {
    return axiosInstance.get(
        Config.apiUrl +
        url +
        `questionsubject/list`,
    );
};

const getListExportData = (data: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `mcquestion/exportallguessquestions`, data,
    );
}

const deleteOneMCQ = (id: string) => {
    return axiosInstance.delete(
        Config.apiUrl +
        url +
        `mcquestion/delete/${id}`,
    );
}
const deleteMCQ = () => {
    return axiosInstance.delete(
        Config.apiUrl +
        url +
        `mcquestion/deletes`,
    );
}

const createMCQ = (data: any) => {
    return axiosInstance.post(
        Config.apiUrl +
        url +
        `mcquestion/add`,
        data
    );
};
const MCQQuestion = {
    getListMCQ,
    getListSubjectType,
    createMCQ,
    getOneMCQ,
    EditMCQ,
    putFileXLSX,
    getListExportData,
    deleteOneMCQ,
    deleteMCQ
};

export default MCQQuestion;
