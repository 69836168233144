import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Col, Container, Table } from "react-bootstrap";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import MCQQuestion from "../../../services/MCQQuestion.services";
const initialForm = {
  type: "Steady",
  betList: [{ level: 1, ante: 0, bet: 0, lastBet: 0, betAdder: 0, time: 0 }],
};

const EditMCQQuestion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValue, setFormValue] = useState<any>()
  const [answer, setAnswer] = useState<any>()
  const [answerAr, setAnswerAr] = useState<any>()

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<any>({
    defaultValues: initialForm,
  });
  // QUERY
  const { data: listSubjectType } = useQuery({
    queryKey: ["GET_LIST_SUBJECTTYPE"],
    queryFn: (_) => {
      return MCQQuestion.getListSubjectType();
    },
    onError: (err: any) => {
      const { response } = err;
      if (!response.data) {
        toast.error("Connect to server failed.");
      } else {
        toast.error("No Item Found.");
      }
    },
    retry: 1,
  });
  const { isLoading } = useQuery(["GET_ONE_BET"], () => MCQQuestion.getOneMCQ(String(id)), {
    onSuccess: ({ data: res }) => {
      const { data, status } = res;
      if (status) {
        setFormValue(data[0])
        setAnswer(data[0].answers)
        setAnswerAr(data[0].answersAr)
      }
    },
    onError: () => {
      toast.error("Connect to server failed.");
    },
  });
  const EditMCQQuestion = useMutation((data: any) => MCQQuestion.EditMCQ(data), {
    onSuccess: ({ data: res }) => {
      const { data, status } = res;
      if (status) {
        toast.success("Edit MCQ QUESTION SUCCESSFFUL")
      }
    },
    onError: () => {
      toast.error("Connect to server failed.");
    },
  })
  const onInputChange = (e: any) => {
    const { value, name } = e.target
    setFormValue({ ...formValue, [name]: value })
  }
  const onAnswerChange = (e: any, index: any) => {
    const { value } = e.target;
    const newAnswers = [...answer];
    newAnswers[index] = value;
    setAnswer(newAnswers);
  };
  const onAnswerArChange = (e: any, index: any) => {
    const { value, name } = e.target;
    const newAnswers = [...answerAr];
    newAnswers[index] = value;
    setAnswerAr(newAnswers);
  };

  const onsubmit = () => {
    const data = {
      id: id,
      questionSubject: formValue?.subjectType,
      question: formValue?.question,
      questionAr: formValue?.questionAr,
      correctAnswer: formValue?.correctAnswer,
      correctAnswerAr: formValue?.correctAnswerAr,
      answers: formValue?.answers ? formValue?.answers : answer,
      answersAr: formValue?.answersAr ? formValue?.answersAr : answerAr,
      difficultyLevel: formValue?.difficultyLevel
    }
    const checkAnswer = answer?.filter((val: any) => { return val === formValue?.correctAnswer })
    const checkAnswerAr = answerAr?.filter((val: any) => { return val === formValue?.correctAnswerAr })

    checkAnswer?.length > 0 && checkAnswerAr?.length > 0 ? EditMCQQuestion.mutate(data) : toast.error("Please Fill Correct Answer")

  }
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="section-box pb-5">
          <Col>
            <BreadCrumbs
              childItem="View Question Management"
              parentItem="Question Management"
              parentLink="questionManagement"
            />
            <div className="card m-auto">
              <h3 className="page-title">View Question Management</h3>
              <div className="body-form">
                <form onSubmit={handleSubmit(onsubmit)}>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="difficultyLevel" className="form-control-label">
                          Difficulty
                        </label>
                        <select
                          className="form-control"
                          {...register("difficultyLevel")}
                          name="difficultyLevel"
                          value={formValue?.difficultyLevel}
                          onChange={onInputChange}
                        >
                          <option hidden>Diffculty</option>
                          <option value="Easy">
                            Easy
                          </option>
                          <option value="Medium">
                            Medium
                          </option>
                          <option value="Hard">
                            Hard
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="subjectType" className="form-control-label">
                          Subject Type
                        </label>
                        <select
                          className="form-control"
                          {...register("subjectType")}
                          name="subjectType"
                          onChange={onInputChange}
                          value={formValue?.subjectType}
                        >
                          <option hidden>{formValue?.questionsubjectField[0].name}</option>
                          {listSubjectType?.data.data?.map((data: any, index: any) => (
                            <option key={index} value={data._id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="question"
                          className="form-control-label"
                        >
                          Question English
                        </label>
                        <textarea
                          id="question"
                          placeholder="Decription"
                          className="form-control"
                          // rows={3}
                          // cols={40}
                          value={formValue?.question}
                          {...register("question")}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    {formValue?.answers?.map((val: any, index: any) => {
                      return (
                        <div className="col-3">
                          <div className="form-group">
                            <label
                              htmlFor={`ans${index}`}
                              className="form-control-label"
                            >
                              Answer En
                            </label>
                            <input
                              id={`ans${index}`}
                              type="text"
                              placeholder="Name"
                              className="form-control"
                              {...register(`ans${index}`)}
                              value={answer[index]}
                              onChange={(e) => onAnswerChange(e, index)}
                            />
                          </div>
                        </div>
                      )
                    })
                    }
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="questionAr"
                          className="form-control-label"
                        >
                          Question Ar
                        </label>
                        <textarea
                          id="questionAr"
                          placeholder="Decription"
                          className="form-control"
                          // rows={3}
                          // cols={40}
                          value={formValue?.questionAr}
                          {...register("questionAr")}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    {answerAr?.length > 0 && answerAr?.map((val: any, index: any) => {
                      return (
                        <div className="col-3">
                          <div className="form-group">
                            <label
                              htmlFor={`ansAr${index}`}
                              className="form-control-label"
                            >
                              Answer Ar
                            </label>
                            <input
                              id={`ansAr${index}`}
                              type="text"
                              placeholder={`Answer`}
                              className="form-control"
                              {...register(`ansAr${index}`)}
                              value={answerAr[index]}
                              onChange={(e) => onAnswerArChange(e, index)}
                            />
                          </div>
                        </div>
                      )
                    })
                    }
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="correctAnswer"
                          className="form-control-label"
                        >
                          Correct Answer En
                        </label>
                        <input
                          id="correctAnswer"
                          type="text"
                          placeholder="correctAnswer"
                          className="form-control"
                          {...register("correctAnswer")}
                          onChange={onInputChange}
                          value={formValue?.correctAnswer}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="description"
                          className="form-control-label"
                        >
                          Correct Answer Ar
                        </label>
                        <input
                          id="correctAnswerAr"
                          type="text"
                          placeholder="correctAnswerAr"
                          className="form-control"
                          {...register("correctAnswerAr")}
                          value={formValue?.correctAnswerAr}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="button-group center-item mt-6">
                    <button className="btn-custom btn-edit btn-margin"
                    // onClick={onsubmit}
                    >
                      Submit
                    </button>
                    <button className="btn-custom btn-edit btn-margin"
                      onClick={() => navigate("/mcqQuestionManagement")}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};

export default EditMCQQuestion;
