export const validationLogin = (value: any) => {
  let errors = {
    username: "",
    password: "",
  };
  if (!value.username) errors.username = "Email is required!";
  if (!value.password) {
    errors.password = "Password is required!";
  } else if (value.password.length < 6) {
    errors.password = "Password must be more than eight characters!";
  }
  return errors;
};
export const validationCreateTimeBank = (timeBank: any, table: any) => {
  let errors = {
    name: "",
    desc: "",
    level: "",
    time: "",
  };
  if (!timeBank.name || timeBank.name.trim() === "")
    errors.name = "Name is required!";
  if (!timeBank.desc || timeBank.desc.trim() === "")
    errors.desc = "Description is required!";
  return errors;
};
export const validationEditTimeBank = (timeBank: any, table: any) => {
  let errors = {
    name: "",
    desc: "",
    level: "",
    time: "",
  };
  if (!timeBank.description || timeBank?.description?.trim() === "")
    errors.desc = "Description is required!";
  return errors;
};
export const validationBlindStructure = (data: any, table: any) => {
  let errors: any = {
    name: "",
    desc: "",
  };
  if (!data.name || data.name.trim() === "") errors.name = "Name is required!";
  if (!data.desc || data.desc.trim() === "")
    errors.desc = "Description is required!";
  return errors;
};
export const validationCreatePayout = (payout: any) => {
  let errors = {
    name: "",
  };
  if (!payout.name) errors.name = "Name is required!";
  return errors;
};
export const validationCreateTournament = (data: any) => {
  let errors: any = {
    actionTime: "",
    addOnChip: "",
    addOnPrice: "",
    entryFees: "",
    freezeOut: "",
    gameVariation: "",
    houseFees: "",
    isAddTimeBank: "",
    isAddonTimeEnabled: "",
    isRebuyAllowed: "",
    isRebuyTicket: "",
    isReentryAllowed: "",
    isReentryTicket: "",
    lastRebuy: "",
    lastReentry: "",
    lateRegistrationAllowed: "",
    lateRegistrationTime: "",
    maxPlayersAllowed: "",
    minPlayersToStart: "",
    numberOfAddOn: "",
    numberOfRebuy: "",
    numberOfReentry: "",
    payoutType: "",
    playerPerTables: "",
    rebuyAmount: "",
    rebuyChip: "",
    rebuyCondition: "",
    rebuyHouseFee: "",
    guaranteedValue: "",
    rebuyRealChip: "",
    reentryChip: "",
    reentryHouseFee: "",
    reentryRealChip: "",
    registrationStartTime: "",
    startingChips: "",
    timeBank: "",
    totalEntryFees: "",
    totalRebuyChip: "",
    totalreentryChip: "",
    tournamentName: "",
    tournamentStartTime: "",
    tournamentType: "",
    timeBankRuleId: "",
    unregisterCutOffTime: "",
  };
  if (data.actionTime.trim() === "")
    errors.actionTime = "Action Time is required !";
  if (data.addOnChip === "") errors.addOnChip = "Addon Chips is required !";
  if (data.actionTime.trim() === "")
    errors.actionTime = "Action Time is required !";
  if (data.blindStructureId.trim() === "")
    errors.blindStructureId = "Blind Structure is required !";
  if (data.entryFees === "") errors.entryFees = "Entry Fees is required !";
  if (data.gameVariation.trim() === "")
    errors.gameVariation = "Game Varation is required !";
  if (data.guaranteedValue === "")
    errors.guaranteedValue = "Guaranteed Value is required !";
  if (data.isAddTimeBank === "")
    errors.isAddTimeBank = "Additional Time Bank is required !";
  if (data.isAddonTimeEnabled === "")
    errors.isAddonTimeEnabled = "Addon Allowed is required !";
  if (data.isGtdEnabled === "")
    errors.isGtdEnabled = "Guaranteed Tournament is required !";
  if (data.isRebuyAllowed === "")
    errors.isRebuyAllowed = "Rebuy Allowed is required !";
  if (data.isReentryAllowed === "")
    errors.isReentryAllowed = "Reentry Allowed is required !";
  if (data.lastRebuy === "") errors.lastRebuy = "Last Rebuy is required !";
  if (data.lastReentry === "")
    errors.lastReentry = "Last Reentry is required !";
  if (data.lateRegistrationAllowed === "")
    errors.lateRegistrationAllowed = "Late Registration Allowed is required !";
  if (data.lateRegistrationTime === "")
    errors.lateRegistrationTime = "Late Registration Time is required !";
  if (data.maxPlayersAllowed === "")
    errors.maxPlayersAllowed = "Max Players Allowed is required !";
  if (data.minPlayersToStart === "")
    errors.minPlayersToStart = "Min Players to Start is required !";
  if (data.numberOfRebuy === "")
    errors.numberOfRebuy = "No of Rebuy is required !";
  if (data.numberOfReentry === "")
    errors.numberOfReentry = "No of Reentry is required !";
  if (data.payoutId === "") errors.payoutId = "Payout is required !";
  if (data.payoutType === "") errors.payoutType = "Payout Type is required !";
  if (data.playerPerTables === "")
    errors.playerPerTables = "Player Per Table is required !";
  if (data.rebuyAmount === "")
    errors.rebuyAmount = "Rebuy Amount is required !";
  if (data.rebuyChip === "") errors.rebuyChip = "Rebuy Chip is required !";
  if (data.rebuyCondition === "")
    errors.rebuyCondition = "Rebuy Condition is required !";
  if (data.rebuyHouseFee === "")
    errors.rebuyHouseFee = "Rebuy House Fee is required !";
  if (data.reentryAmount === "")
    errors.reentryAmount = "Reentry Amount is required !";
  if (data.reentryChip === "") errors.reentryChip = "Reetry Chip is required !";
  if (data.reentryHouseFee === "")
    errors.reentryHouseFee = "Reentry House Fee is required !";
  if (data.registrationStartTime === "")
    errors.registrationStartTime = "Registration Start Time is required !";
  if (data.startingChips === "")
    errors.startingChips = "Starting Chips is required !";
  if (data.timeBank === "") errors.timeBank = "Time Bank is required !";
  if (data.timeBankRuleId === "")
    errors.timeBankRuleId = "Additional Time Bank Rule is required !";
  if (data.tournamentName === "") {
    errors.tournamentName = "Tournament Name is required !";
  }
  if (data.tournamentStartTime === "")
    errors.tournamentStartTime = "Tournament Start Time is required !";
  if (data.tournamentType.trim() === "")
    errors.tournamentType = "Tournament Type is required !";
  if (data.unregisterCutOffTime === "")
    errors.unregisterCutOffTime = "Unregister cut off Time is required !";
  // if (Number(data.timeBank) >= 60) {
  //   errors.timeBank = "Please Enter valid time in seconds.";
  // }
  // if (
  //   Number(data.unregisterCutOffTime) > 2 &&
  //   Number(data.unregisterCutOffTime) < 5
  // ) {
  //   errors.unregisterCutOffTime = "";
  // } else {
  //   errors.unregisterCutOffTime =
  //     "Unregister cut off Time is from 2 to 5 minutes.";
  // }

  if (
    !data.rebuyRealChip ||
    String(data.rebuyRealChip) === "false" ||
    data.rebuyRealChip === ""
  ) {
    errors.rebuyAmount = "";
    errors.rebuyHouseFee = "";
  }
  if (String(data.rebuyVipPoint) === "false" || data.rebuyVipPoint === "") {
    errors.rebuyPoint = "";
  }
  if (String(data.isRebuyTicket) === "false" || data.isRebuyTicket === "") {
    errors.rebuyTicket = "";
  }
  if (
    !data.reentryRealChip ||
    String(data.reentryRealChip) === "false" ||
    data.reentryRealChip === ""
  ) {
    errors.reentryAmount = "";
    errors.reentryHouseFee = "";
  }
  if (String(data.isGtdEnabled) === "false" || data.isGtdEnabled === "") {
    errors.guaranteedValue = "";
  }
  if (data.tournamentType === "freeRoll" || data.tournamentType === "") {
    errors.entryFees = "";
    errors.houseFees = "";
  }
  if (data.entryFees === "" || data.houseFees === "") {
    errors.totalEntryFees = "";
  }
  if (data.rebuyAmount === "" || data.rebuyHouseFee === "") {
    errors.totalRebuyChip = "";
  }
  if (data.reentryAmount === "" || data.reentryHouseFee === "") {
    errors.totalreentryChip = "";
  }
  if (
    String(data.lateRegistrationAllowed) === "false" ||
    data.lateRegistrationAllowed === ""
  )
    errors.lateRegistrationTime = "";
  if (String(data.isRebuyAllowed) === "false" || data.isRebuyAllowed === "") {
    errors.rebuyCondition = "";
    errors.rebuyChip = "";
    errors.numberOfRebuy = "";
    errors.rebuyAmount = "";
    errors.rebuyHouseFee = "";
    errors.rebuyPoint = "";
    errors.rebuyTicket = "";
    errors.lastRebuy = "";
  }
  if (
    String(data.isReentryAllowed) === "false" ||
    String(data.isReentryAllowed) === ""
  ) {
    errors.reentryCondition = "";
    errors.reentryChip = "";
    errors.numberOfReentry = "";
    errors.reentryAmount = "";
    errors.reentryHouseFee = "";
    errors.reentryPoint = "";
    errors.reentryTicket = "";
    errors.lastReentry = "";
  }
  if (Number(data.playerPerTables) >= Number(data.maxPlayersAllowed)) {
    errors.playerPerTables = "Player per table must be less than Max player";
  }
  if (
    String(data.isAddonTimeEnabled) === "false" ||
    data.isAddonTimeEnabled === ""
  ) {
    errors.numberOfAddOn = "";
    errors.addOnPrice = "";
    errors.addOnChip = "";
    errors.addOnTime = "";
  }
  if (String(data.isAddTimeBank) === "false" || data.isAddTimeBank === "") {
    errors.timeBankRuleId = "";
  }
  return errors;
};
export const validationCreateTournamentEmpty = (
  formValue: any,
  errors: any
) => {
  if (
    formValue.tournamentName.trim() !== "" &&
    formValue.tournamentName !== "0"
  ) {
    errors.tournamentName = "";
  }
  if (formValue.tournamentType && formValue.tournamentType !== "0")
    errors.tournamentType = "";
  if (formValue.gameVariation && formValue.gameVariation !== "0")
    errors.gameVariation = "";
  if (formValue.actionTime && formValue.actionTime !== "0")
    errors.actionTime = "";
  if (formValue.addOnPrice && formValue.addOnPrice !== "0")
    errors.addOnPrice = "";
  if (formValue.isGtdEnabled && formValue.isGtdEnabled !== "0")
    errors.isGtdEnabled = "";
  if (formValue.guaranteedValue && formValue.guaranteedValue !== "0")
    errors.guaranteedValue = "";
  if (formValue.addOnChip && formValue.addOnChip !== "0") errors.addOnChip = "";
  if (formValue.payoutId && formValue.payoutId !== "0") errors.payoutId = "";
  if (formValue.timeBankRuleId && formValue.timeBankRuleId !== "0")
    errors.timeBankRuleId = "";
  if (formValue.entryFees) errors.entryFees = "";
  if (formValue.freezeOut) errors.freezeOut = "";
  if (formValue.gameVariation) errors.gameVariation = "";
  if (formValue.houseFees) errors.houseFees = "";
  if (formValue.blindStructureId) errors.blindStructureId = "";
  if (formValue.isAddTimeBank) errors.isAddTimeBank = "";
  if (formValue.isAddonTimeEnabled) errors.isAddonTimeEnabled = "";
  if (formValue.isRebuyAllowed) errors.isRebuyAllowed = "";
  if (formValue.isRebuyTicket) errors.isRebuyTicket = "";
  if (formValue.isReentryAllowed) errors.isReentryAllowed = "";
  if (formValue.isReentryTicket) errors.isReentryTicket = "";
  if (Number(formValue.lastRebuy) > 0) errors.lastRebuy = "";
  if (Number(formValue.lastReentry) > 0) errors.lastReentry = "";
  if (formValue.lateRegistrationAllowed) errors.lateRegistrationAllowed = "";
  if (formValue.lateRegistrationTime) errors.lateRegistrationTime = "";
  if (
    formValue.maxPlayersAllowed &&
    +formValue.maxPlayersAllowed > +formValue.minPlayersToStart &&
    +formValue.maxPlayersAllowed > +formValue.playerPerTables
  )
    errors.maxPlayersAllowed = "";
  if (
    formValue.minPlayersToStart &&
    +formValue.minPlayersToStart < +formValue.maxPlayersAllowed
  )
    errors.minPlayersToStart = "";
  if (formValue.numberOfAddOn && formValue.numberOfAddOn !== "0")
    errors.numberOfAddOn = "";
  if (formValue.numberOfRebuy && formValue.numberOfRebuy !== "0")
    errors.numberOfRebuy = "";
  if (formValue.numberOfReentry && formValue.numberOfReentry !== "0")
    errors.numberOfReentry = "";
  if (formValue.payoutType && formValue.payoutType !== "0")
    errors.payoutType = "";
  if (formValue.numberOfReentry && formValue.numberOfReentry !== "0")
    errors.numberOfReentry = "";
  if (formValue.rebuyAmount && formValue.rebuyAmount !== "0")
    errors.rebuyAmount = "";
  if (formValue.rebuyChip && formValue.rebuyChip !== "0") errors.rebuyChip = "";
  if (formValue.rebuyHouseFee && formValue.rebuyHouseFee !== "0")
    errors.rebuyHouseFee = "";
  if (formValue.rebuyPoint && formValue.rebuyPoint !== "0")
    errors.rebuyPoint = "";
  if (formValue.rebuyRealChip && formValue.rebuyRealChip !== "0")
    errors.rebuyRealChip = "";
  if (formValue.rebuyTicket && formValue.rebuyTicket !== "0")
    errors.rebuyTicket = "";
  if (formValue.rebuyVipPoint && formValue.rebuyVipPoint !== "0")
    errors.rebuyVipPoint = "";
  if (formValue.reentryAmount && formValue.reentryAmount !== "0")
    errors.reentryAmount = "";
  if (formValue.reentryChip && formValue.reentryChip !== "0")
    errors.reentryChip = "";
  if (formValue.reentryHouseFee && formValue.reentryHouseFee !== "0")
    errors.reentryHouseFee = "";
  if (formValue.reentryPoint && formValue.reentryPoint !== "0")
    errors.reentryPoint = "";
  if (formValue.reentryRealChip && formValue.reentryRealChip !== "0")
    errors.reentryRealChip = "";
  if (formValue.reentryTicket && formValue.reentryTicket !== "0")
    errors.reentryTicket = "";
  if (formValue.reentryVipPoint && formValue.reentryVipPoint !== "0")
    errors.reentryVipPoint = "";
  if (
    formValue.registrationStartTime &&
    formValue.registrationStartTime !== "0"
  )
    errors.registrationStartTime = "";
  if (formValue.startingChips && formValue.startingChips !== "0")
    errors.startingChips = "";
  // if (Number(formValue.timeBank) < 60 && Number(formValue.timeBank) > 0)
  //   errors.timeBank = "";
  // if (
  //   Number(formValue.rebuyCondition) <= 100 &&
  //   Number(formValue.rebuyCondition) > 0
  // )
  //   errors.timeBank = "";

  if (formValue.timeBank) {
    errors.timeBank = "";
  }
  if (formValue.unregisterCutOffTime) {
    errors.unregisterCutOffTime = "";
  }
  if (formValue.totalEntryFees && formValue.totalEntryFees !== "0")
    errors.totalEntryFees = "";
  if (formValue.totalRebuyChip && formValue.totalRebuyChip !== "0")
    errors.totalRebuyChip = "";
  if (formValue.totalreentryChip && formValue.totalreentryChip !== "0")
    errors.totalreentryChip = "";
  if (formValue.tournamentStartTime && formValue.tournamentStartTime !== "0")
    errors.tournamentStartTime = "";
  if (formValue.tournamentType && formValue.tournamentType !== "0")
    errors.tournamentType = "";
  return errors;
};
export const blockInvalidChar = (e: any) => {
  const alphabets = [
    "a",
    "b",
    "c",
    " ",
    "d",
    "#",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "e",
    "E",
    "/",
    "*",
    ";",
    '"',
    "'",
    "Y",
    "Z",
    "+",
    "-",
    ",",
    ".",
  ];

  if (e.target.value.length === 0 && e.key === "0") {
    e.preventDefault();
  } else if (alphabets.includes(e.key)) {
    e.preventDefault();
  }
};
export const blockInvalidSpecialChar = (e: any) => {
  const alphabets = ["/", "*", "+", "-", "#", "@", "^", "~"];
  if (e.target.value.length === 0 && e.key === "0") {
    e.preventDefault();
  } else if (alphabets.includes(e.key)) {
    e.preventDefault();
  }
};
export const preventSerachChange = (e: any) => {
  let pattern = /\w+\s*/;
  if (e.target.value.length === 0 && e.key === "0") {
    e.preventDefault();
  } else if (e.key === " ") {
    return true;
  } else if (e.key === "_") {
    e.preventDefault();
  } else if (pattern.test(e.key)) {
    return true;
  } else {
    e.preventDefault();
  }
};

export const preventTournamentName = (e: any) => {
  let pattern = /\w+\s*/;
  if (e.target.value.length === 0 && e.key === "0") {
    e.preventDefault();
  } else if (e.key === " ") {
    return true;
  } else if (e.key === "@") {
    return true;
  } else if (pattern.test(e.key)) {
    return true;
  } else {
    e.preventDefault();
  }
};

export const preventCreateData = (e: any) => {
  let pattern = /^(\w+\s?)*\s*$/;
  if (pattern.test(e)) {
    return true;
  } else {
    return false;
  }
};
export const checkTextAndNumber = (e: any) => {
  let pattern = /[A-z]/;
  if (pattern.test(e)) {
    return true;
  } else {
    return false;
  }
};

export const checkHi = (e: any) => {};
