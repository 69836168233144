import { useEffect, useState } from "react";
import { Button, Col, TabContainer } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { encryptionEnable } from "../../config/config";
// import blindStructureService from "../../services/blindStructure.services";
// import payoutStructure from "../../services/payoutStructure.services";
// import timeBankService from "../../services/timeBank.services";
import tournamentServices from "../../services/tournament.services";
import "../../styles/createtournament.css";
import * as ulti from "../../utils/decrypt";
import {
  blockInvalidChar,
  checkTextAndNumber,
  preventSerachChange,
  preventTournamentName,
  validationCreateTournament,
  validationCreateTournamentEmpty,
} from "../../utils/validation/validation";

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Loading from "../../components/loading/Loading";
const gameVariationArr = [
  {
    id: 1,
    title: "NL Holdem",
    value: "NLH",
  },
  {
    id: 2,
    title: "Omaha",
    value: "PLO",
  },
];

const chipsTypeArr = [
  {
    id: 1,
    title: "Real",
    value: true,
  },
  {
    id: 2,
    title: "Play",
    value: false,
  },
];
const PayoutType = [
  {
    id: 1,
    title: "Chips",
    value: "chips",
  },
  {
    id: 2,
    title: "Tickets",
    value: "ticket",
  },
];
const tournamentTypeArr = [
  {
    id: 1,
    title: "Free Roll",
    value: "freeRoll",
  },
  {
    id: 2,
    title: "Normal",
    value: "normal",
  },
  {
    id: 3,
    title: "Satellite",
    value: "satellite",
  },
];

const actionTimeArr = [
  {
    id: 1,
    title: "Standard(30 sec)",
    value: "30",
  },
  {
    id: 2,
    title: "Turbo(20 sec)",
    value: "20",
  },
  {
    id: 3,
    title: "Hyper Turbo(10 sec)",
    value: "10",
  },
];
const initDataFormValue = {
  actionTime: "",
  addOnChip: "",
  addOnPrice: "",
  entryFees: "",
  freezeOut: "",
  gameVariation: "",
  houseFees: "",
  isAddTimeBank: "",
  isAddonTimeEnabled: "",
  isRebuyAllowed: "",
  isReentryAllowed: "",
  lastRebuy: "",
  lastReentry: "",
  timeBankRuleId: "",
  lateRegistrationAllowed: "",
  lateRegistrationTime: "",
  maxPlayersAllowed: "",
  rebuyChip: "",
  rebuyPoint: "",
  rebuyTicket: "",
  reentryChip: "",
  reentryTicket: "",
  payoutId: "",
  minPlayersToStart: "",
  numberOfAddOn: "",
  numberOfRebuy: "",
  numberOfReentry: "",
  blindStructureId: "",
  payoutType: "",
  playerPerTables: "",
  rebuyAmount: "",
  rebuyHouseFee: "",
  reentryAmount: "",
  reentryHouseFee: "",
  startingChips: "",
  guaranteedValue: "",
  timeBank: "",
  tournamentName: "",
  tournamentType: "",
  isGtdEnabled: "",
  rebuyCondition: "",
  unregisterCutOffTime: "",
  reentryPoint: "",
  registrationBeforeStarttime: "",
  tournamentStartTime: "",
  addOnTime: {},
};
const initIsRebuyPrice = {
  isRebuyPrice: false,
  isRebuyRealChip: false,
  isRebuyVipPoint: false,
  isRebuyTicket: false,
  isReenTryPrice: false,
  isReenTryRealChip: false,
  isReenTryVipPoint: false,
  isReenTryTicket: false,
  isSelectAddon: false,
};
const initMaxValidate = {
  startingChips: "",
  timeBank: "",
  minPlayersToStart: "",
  maxPlayersAllowed: "",
  playerPerTables: "",
};

const CreateTournament = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [minT, setMinT] = useState<any>(new Date().getTime());
  const [minTimeRegist, setMinTimeRegist] = useState<any>(new Date().getTime());
  const [startDate2, setStartDate2] = useState<any>(new Date());
  const [formValue, setFormValue] = useState<any | null>(initDataFormValue);
  const [addOn, setAddOn] = useState<any>({});
  const [errors, setErrors] = useState<any | null>(initDataFormValue);
  const [dataBlindStructure, setDataBlindStructure] = useState([]);
  const [dataTimeBank, setDataTimeBank] = useState([]);
  const [listCountAddOn, setListCountAddOn] = useState(0);
  const [toggleTournamentType, setToggleTournamentType] = useState(false);
  const [toggleGuaranteed, setToggleGuaranteed] = useState(false);
  const [isAddTimeBank, setAddIsTimeBank] = useState(false);
  const [registrationAllowed, setRegistrationAllowed] = useState(false);
  const [dataPayout, setDataPayout] = useState([]);
  const [selectPrice, setSelectPrice] = useState(initIsRebuyPrice);
  const [maxValidate, setMaxValidate] = useState(initMaxValidate);
  useEffect(() => {
    initDataBlindStructure();
    initDataTimeBank();
    initDataPayoutStructure();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const initDataBlindStructure = () => {
    // blindStructureService.getAll().then((res: any) => {
    //   if (res.data.status === "success") {
    //     if (encryptionEnable) {
    //       if (res.data.data) {
    //         ulti.decrypt(res.data.data).then((listBlind: any) => {
    //           setDataBlindStructure(listBlind);
    //         });
    //       }
    //     } else {
    //       setDataBlindStructure(res.data.data);
    //     }
    //   } else {
    //     toast.error("Get Data Blind Failed");
    //   }
    // });
  };
  const initDataTimeBank = () => {
    // timeBankService.getAll().then((data: any) => {
    //   if (data.status === 200) {
    //     if (encryptionEnable) {
    //       if (data.data.data) {
    //         ulti.decrypt(data.data.data).then((listTimeBank: any) => {
    //           setDataTimeBank(listTimeBank);
    //         });
    //       }
    //     } else {
    //       setDataTimeBank(data.data.data);
    //     }
    //   } else {
    //     toast.error("Get Data Time Bank Failed");
    //   }
    // });
  };

  const initDataPayoutStructure = () => {
    // payoutStructure.getAll().then((data: any) => {
    //   if (data.data.status === "success") {
    //     if (encryptionEnable) {
    //       if (data.data.data) {
    //         ulti.decrypt(data.data.data).then((listPayout: any) => {
    //           setDataPayout(listPayout);
    //         });
    //       }
    //     } else {
    //       setDataPayout(data.data.data);
    //     }
    //   } else {
    //     toast.error("Get Data Payout Structure Failed");
    //   }
    // });
  };

  const initTime = (number: number) => {
    if (number < 10) {
      return Number("0" + number);
    } else {
      return number;
    }
  };
  const handleDatePickerRegistration = (e: any) => {
    setStartDate(e);
    const today = new Date();

    if (today.getTime() > e.getTime()) {
      const hour = today.getHours();
      const minus = today.getMinutes();

      setMinT(
        setHours(setMinutes(startDate2, initTime(minus + 1)), initTime(hour))
      );
    } else {
      const hour = e.getHours();
      const minus = e.getMinutes();
      setMinTimeRegist(setHours(setMinutes(e, 0), 0));
      setMinT(
        setHours(setMinutes(startDate2, initTime(minus + 1)), initTime(hour))
      );
    }

    if (today.getDate() === e.getDate() && today.getMonth === e.getMonth) {
      setMinTimeRegist(today.getTime());
    }

    setFormValue({
      ...formValue,
      registrationBeforeStarttime: e,
    });
  };
  const handleDatePickerTournament = (e: any) => {
    setStartDate2(e);

    const today = new Date();

    const date = startDate.getDate();
    if (startDate.getTime() < e.getTime()) {
      setMinT(setHours(setMinutes(startDate2, 0), 0));
    }
    if (date === e.getDate() && startDate.getMonth() === e.getMonth()) {
      const hour = startDate.getHours();
      const minus = startDate.getMinutes();

      setMinT(
        setHours(setMinutes(startDate2, initTime(minus + 1)), initTime(hour))
      );
    }

    if (today.getDate() === e.getDate() && today.getMonth() === e.getMonth()) {
      const hour = today.getHours();
      const minus = today.getMinutes();

      setMinT(
        setHours(setMinutes(startDate2, initTime(minus + 1)), initTime(hour))
      );
    }

    setFormValue({
      ...formValue,
      tournamentStartTime: e,
    });
  };
  //
  validationCreateTournamentEmpty(formValue, errors);

  const handleMaxValidateOnchange = (name: any, value: number) => {
    setMaxValidate({ ...maxValidate, [name]: "" });
    if (
      (name === "startingChips" ||
        name === "timeBank" ||
        name === "minPlayersToStart" ||
        name === "maxPlayersAllowed" ||
        name === "maxPlayersToStart" ||
        name === "playerPerTables") &&
      +value > 10000000
    ) {
      setMaxValidate({
        ...maxValidate,
        [name]: `This field must less more than 10000000`,
      });
    }
  };
  const onInputChange = (e: any) => {
    let { value, name, checked, type } = e.target;
    // if (type === "number") {
    //   if (value === "") {
    //     setErrors({ ...errors, [name]: `${name} is Required` });
    //   } else if (Number(value) <= 0) {
    //     setErrors({ ...errors, [name]: `${name} must be greater than 0` });
    //   }
    // }
    if (value === "") {
      setErrors({ ...errors, [name]: `This field is required` });
    }
    handleMaxValidateOnchange(name, +value);

    setFormValue({ ...formValue, [name]: value });

    if (
      !checked &&
      (name === "rebuyRealChip" ||
        name === "rebuyVipPoint" ||
        name === "isRebuyTicket" ||
        name === "reentryRealChip" ||
        name === "reentryVipPoint" ||
        name === "isReentryTicket")
    ) {
      setFormValue({ ...formValue, [name]: "false" });
    } else if (
      checked &&
      (name === "rebuyRealChip" ||
        name === "rebuyVipPoint" ||
        name === "isRebuyTicket" ||
        name === "reentryRealChip" ||
        name === "reentryVipPoint" ||
        name === "isReentryTicket")
    ) {
      setFormValue({ ...formValue, [name]: "true" });
    }
    validationCreateTournamentEmpty(formValue, errors);
  };

  const handleAddon = (e: any) => {
    let { value } = e.target;
    if (+value <= 100) {
      setListCountAddOn(value);
      let sizeAddon = Object.keys(addOn).length;
      let ArrAddOn = Object.entries(addOn);

      ArrAddOn.splice(+value, +sizeAddon);

      let NewAddOnObject = ArrAddOn.reduce(
        (previousValue, currentValue, index) => ({
          ...previousValue,
          [`blindLevel${index}`]: currentValue[1],
        }),
        {}
      );
      setAddOn(NewAddOnObject);
    }
  };

  const onInputChangeCondition = (e: any) => {
    let { value } = e.target;
    if (+value > 100) {
      setErrors({
        ...errors,
        rebuyCondition: "Percentage should not be greater than 100",
      });
    } else if (value <= 0) {
      setErrors({
        ...errors,
        rebuyCondition: "Rebuy Condition must be greater than 0",
      });
    } else {
      setErrors({ ...errors, rebuyCondition: "" });
    }
    validationCreateTournamentEmpty(formValue, errors);

    setFormValue({ ...formValue, rebuyCondition: value });
  };

  // const onInPutChangeTimeBank = (e: any) => {
  //   let { value } = e.target;
  //   if (value >= 60) {
  //     setErrors({ ...errors, timeBank: "Please Enter valid time in seconds." });
  //   } else if (value <= 0) {
  //     setErrors({ ...errors, timeBank: "Time Bank must be greater than 0" });
  //   } else {
  //     setErrors({ ...errors, timeBank: "" });
  //   }
  //   validationCreateTournamentEmpty(formValue, errors);

  //   setFormValue({ ...formValue, timeBank: value });
  // };

  // const onChangeUnregisterTime = (e: any) => {
  //   let { value } = e.target;
  //   if (value > 2 && value < 5) {
  //     setErrors({
  //       ...errors,
  //       unregisterCutOffTime: "",
  //     });
  //   } else {
  //     setErrors({
  //       ...errors,
  //       unregisterCutOffTime: "Unregister cut off Time is from 2 to 5 minutes.",
  //     });
  //   }
  //   validationCreateTournamentEmpty(formValue, errors);

  //   setFormValue({ ...formValue, unregisterCutOffTime: value });
  // };

  const onInputChangeMinPlayer = (e: any) => {
    let { name, value } = e.target;

    if (+value >= +formValue.maxPlayersAllowed) {
      setErrors({
        ...errors,
        minPlayersToStart: "Min players should be less than Max players",
      });
    }
    handleMaxValidateOnchange(name, +value);
    // else if (+value > +formValue.playerPerTables) {
    //   setErrors({
    //     ...errors,
    //     minPlayersToStart: "Min players should be less than Player Per Tables",
    //   });
    // }
    setFormValue({ ...formValue, minPlayersToStart: value });
  };

  const onInputChangeMaxPlayers = (e: any) => {
    let { value, name } = e.target;
    if (+value <= +formValue.minPlayersToStart) {
      setErrors({
        ...errors,
        maxPlayersAllowed: "Max players should be greater than min players",
      });
    } else if (+value <= +formValue.playerPerTables) {
      setErrors({
        ...errors,
        maxPlayersAllowed:
          "Max players should be greater than Player Per Tables",
      });
    } else if (+value > +formValue.playerPerTables) {
      setErrors({ ...errors, playerPerTables: "" });
    }
    handleMaxValidateOnchange(name, +value);

    setFormValue({ ...formValue, maxPlayersAllowed: value });
  };

  const onInputChangePlayerPerTable = (e: any) => {
    let { value, name } = e.target;

    if (Number(value) >= Number(formValue.maxPlayersAllowed)) {
      setErrors({
        ...errors,
        playerPerTables: "Player per table must be less than Max player",
      });
      setFormValue({ ...formValue, playerPerTables: value });
    } else {
      setErrors({ ...errors, playerPerTables: "" });
      setFormValue({ ...formValue, playerPerTables: value });
    }
    handleMaxValidateOnchange(name, +value);
  };

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    if (checkTextAndNumber(formValue.tournamentName)) {
      setFormValue({
        ...formValue,
        totalEntryFees: String(+formValue.entryFees + +formValue.houseFees),
        totalRebuyChip: String(
          Number(formValue.rebuyAmount) + Number(formValue.rebuyHouseFee)
        ),
        totalreentryChip: String(
          Number(formValue.reentryAmount) + Number(formValue.reentryHouseFee)
        ),
      });
      CreateTournament(formValue);
    } else {
      toast.warn(
        "Tournament name should not be submitted without any characters!"
      );
    }
  };
  useEffect(() => {
    setFormValue({
      ...formValue,
      addOnTime: addOn,
    });
  }, [addOn]);
  const CreateTournament = (data: any) => {
    let errorList = validationCreateTournament(data);
    const dataErrorsArrayValue: any = Object.values(errorList);
    setErrors(errorList);

    if (dataErrorsArrayValue.every((error: any) => error === "")) {
      tournamentServices.create(data).then(
        (res: any) => {
          let data = res.data;
          if (data.status === "Tournament created successfully") {
            toast.success("Tournament created successfully");
            Navigate("/listTournament");
          } else if (data.status === "Tournament Name is already exists") {
            toast.warn(data.status);
          } else if (
            data.status ===
            "Registration start time can not be greater than or equal to Tournament start time"
          ) {
            toast.warn(data.status);
          } else if (
            data.status ===
            "min player or player per table should not be greater than or equal to max player"
          ) {
            toast.warn(data.status);
          }
        },
        (error: any) => {
          toast.error("Tournament create failed");
        }
      );
    }
  };

  const handleAddOnChangeValue = (e: any) => {
    let { value, name } = e.target;

    setAddOn({ ...addOn, [name]: value });
  };

  const changeSelectType = (e: any) => {
    let { value, name, checked } = e.target;
    // TOURNAMENT_TYPE
    if (
      (value === "satellite" && name === "tournamentType") ||
      (value === "normal" && name === "tournamentType")
    ) {
      setToggleTournamentType(true);
    } else if (value === "freeRoll" && name === "tournamentType") {
      setToggleTournamentType(false);
    }

    // GUARANTEED_TOURNAMENT
    if (value === "true" && name === "isGtdEnabled") {
      setToggleGuaranteed(true);
    } else if (value === "false" && name === "isGtdEnabled") {
      setToggleGuaranteed(false);
    }

    // IS_ADD_TIME_BANK
    if (value === "true" && name === "isAddTimeBank") {
      setAddIsTimeBank(true);
    } else if (value === "false" && name === "isAddTimeBank") {
      setAddIsTimeBank(false);
    }

    // REGISTRATION_ALLOWED

    if (value === "true" && name === "lateRegistrationAllowed") {
      setRegistrationAllowed(true);
    } else if (value === "false" && name === "lateRegistrationAllowed") {
      setRegistrationAllowed(false);
    }
    // SELECT_REBUY
    if (value === "true" && name === "isRebuyAllowed") {
      setSelectPrice({ ...selectPrice, isRebuyPrice: true });
    } else if (value === "false" && name === "isRebuyAllowed") {
      setSelectPrice({ ...selectPrice, isRebuyPrice: false });
    }
    if (checked && name === "rebuyRealChip") {
      setSelectPrice({ ...selectPrice, isRebuyRealChip: true });
    } else if (checked === false && name === "rebuyRealChip") {
      setErrors({ ...errors, rebuyAmount: "", rebuyHouseFee: "" });
      setSelectPrice({ ...selectPrice, isRebuyRealChip: false });
    }
    if (checked && name === "rebuyVipPoint") {
      setSelectPrice({ ...selectPrice, isRebuyVipPoint: true });
    } else if (checked === false && name === "rebuyVipPoint") {
      setErrors({ ...errors, rebuyPoint: "" });
      setSelectPrice({ ...selectPrice, isRebuyVipPoint: false });
    }
    if (checked && name === "isRebuyTicket") {
      setSelectPrice({ ...selectPrice, isRebuyTicket: true });
    } else if (checked === false && name === "isRebuyTicket") {
      setErrors({ ...errors, rebuyTicket: "" });
      setSelectPrice({ ...selectPrice, isRebuyTicket: false });
    }

    // SELECT_REENTRY

    if (value === "true" && name === "isReentryAllowed") {
      setSelectPrice({ ...selectPrice, isReenTryPrice: true });
    } else if (value === "false" && name === "isReentryAllowed") {
      setSelectPrice({ ...selectPrice, isReenTryPrice: false });
    }

    if (checked && name === "reentryRealChip") {
      setSelectPrice({ ...selectPrice, isReenTryRealChip: true });
    } else if (checked === false && name === "reentryRealChip") {
      setSelectPrice({ ...selectPrice, isReenTryRealChip: false });
    }
    if (checked && name === "reentryVipPoint") {
      setSelectPrice({ ...selectPrice, isReenTryVipPoint: true });
    } else if (checked === false && name === "reentryVipPoint") {
      setSelectPrice({ ...selectPrice, isReenTryVipPoint: false });
    }
    if (checked && name === "isReentryTicket") {
      setSelectPrice({ ...selectPrice, isReenTryTicket: true });
    } else if (checked === false && name === "isReentryTicket") {
      setSelectPrice({ ...selectPrice, isReenTryTicket: false });
    }

    // SELECT ADDON
    if (value === "true" && name === "isAddonTimeEnabled") {
      setSelectPrice({ ...selectPrice, isSelectAddon: true });
    } else if (value === "false" && name === "isAddonTimeEnabled") {
      setSelectPrice({ ...selectPrice, isSelectAddon: false });
    }
  };
  return (
    <TabContainer>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank  pb-5">
          <Col>
            <BreadCrumbs childItem="Create Tournament" />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">Create Tournament</h3>
              <div className="body-form">
                <form onSubmit={handleSubmitForm}>
                  {/*Tournament Name */}
                  <div className="form-group flex justify-content-start align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Tournament Name
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="tournamentName"
                        type="text"
                        maxLength={40}
                        placeholder="Enter Tournament Name"
                        className="form-control-input"
                        onChange={onInputChange}
                        onKeyDown={preventTournamentName}
                      />
                      <span className="errors">{errors.tournamentName}</span>
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Game Variation
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="gameVariation"
                        className="form-control-input"
                        onChange={onInputChange}
                      >
                        <option hidden>Game Variation</option>
                        {gameVariationArr?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                      <span className="errors">{errors.gameVariation}</span>
                    </div>
                  </div>
                  {/*Tournament Type */}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Tournament Type
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="tournamentType"
                        className="form-control-input"
                        onChange={(e) => {
                          changeSelectType(e);
                          onInputChange(e);
                        }}
                      >
                        <option hidden>Tournament Type</option>
                        {tournamentTypeArr?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                      <span className="errors">{errors.tournamentType}</span>
                    </div>
                  </div>
                  {/*Guaranteed Tournament*/}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor="guaranteedTournament"
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Guaranteed Tournament
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="isGtdEnabled"
                        id="isGtdEnabled"
                        className="form-control-input"
                        onChange={(e) => {
                          changeSelectType(e);
                          onInputChange(e);
                        }}
                      >
                        <option hidden>Guaranteed Tournament</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <span className="errors">{errors.isGtdEnabled}</span>
                    </div>
                    {toggleGuaranteed && (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Guaranteed Value
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            required
                            name="guaranteedValue"
                            type="number"
                            max={10000000}
                            min={1}
                            onKeyDown={blockInvalidChar}
                            className="form-control-input"
                            onChange={onInputChange}
                          />
                          <span className="errors">
                            {errors.guaranteedValue}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {/* Entry Fee & House Fee */}
                  {toggleTournamentType && (
                    <div className="form-group flex justify-content-start mt-4 align-items-start">
                      <label className="col-md-12 col-lg-12 col-xl-2 col-12">
                        Entry Fees
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <input
                          required
                          name="entryFees"
                          type="number"
                          max={10000000}
                          onKeyDown={blockInvalidChar}
                          min={1}
                          value={formValue.entryFees}
                          onChange={onInputChange}
                          className="form-control-input"
                        />
                        <span className="errors">{errors.entryFees}</span>
                      </div>
                      <label
                        htmlFor="playerPerTable"
                        className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                      >
                        House Fees
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <input
                          required
                          name="houseFees"
                          type="number"
                          max={10000000}
                          min={1}
                          onKeyDown={blockInvalidChar}
                          value={formValue.houseFees}
                          onChange={onInputChange}
                          className="form-control-input"
                        />
                        <span className="errors">{errors.houseFees}</span>
                      </div>
                    </div>
                  )}

                  {/*Total Entry Fees  */}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Total Entry Fees
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="totalEntryFees"
                        type="number"
                        max={10000000}
                        min={1}
                        onKeyDown={blockInvalidChar}
                        value={
                          formValue.tournamentType === "freeRoll"
                            ? "0"
                            : +formValue.entryFees + +formValue.houseFees
                        }
                        className="form-control-input"
                        disabled
                      />
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Starting Chips
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="startingChips"
                        placeholder="Enter Starting Chips"
                        type="number"
                        max={10000000}
                        min={1}
                        onKeyDown={blockInvalidChar}
                        onChange={onInputChange}
                        className="form-control-input"
                      />
                      <span className="errors">{errors.startingChips}</span>
                      <span className="errors">
                        {maxValidate.startingChips}
                      </span>
                    </div>
                  </div>
                  {/*Action time*/}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Min Players to Start
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="minPlayersToStart"
                        placeholder="Enter min player to start"
                        type="number"
                        max={10000000}
                        value={formValue.minPlayersToStart}
                        onKeyDown={blockInvalidChar}
                        min={1}
                        onChange={onInputChangeMinPlayer}
                        className="form-control-input"
                      />
                      {!maxValidate.minPlayersToStart && (
                        <span className="errors">
                          {errors.minPlayersToStart}
                        </span>
                      )}
                      <span className="errors">
                        {maxValidate.minPlayersToStart}
                      </span>
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Max Players At Start
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="maxPlayersAtStart"
                        placeholder="Enter max player at start"
                        type="number"
                        max={10000000}
                        value={formValue?.maxPlayersAtStart}
                        onKeyDown={blockInvalidChar}
                        min={1}
                        onChange={onInputChange}
                        className="form-control-input"
                      />

                      {/* <span className="errors">{errors.maxPlayersAtStart}</span> */}
                    </div>
                  </div>
                  {/* Max Players Allowed */}
                  <div className="form-group flex justify-content-start mt-4   align-items-start">
                    <label
                      htmlFor="maxPlayersAllowed"
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Max Players Allowed
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        id="maxPlayersAllowed"
                        name="maxPlayersAllowed"
                        placeholder="Enter Max player allowed"
                        type="number"
                        min={+formValue.minPlayersToStart + 1 || 1}
                        max={10000000}
                        value={formValue.maxPlayersAllowed}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                          onInputChangeMaxPlayers(e);
                        }}
                        className="form-control-input"
                      />
                      {!maxValidate.maxPlayersAllowed && (
                        <span className="errors">
                          {errors.maxPlayersAllowed}
                        </span>
                      )}
                      <span className="errors">
                        {maxValidate.maxPlayersAllowed}
                      </span>
                    </div>
                    <label
                      htmlFor="playerPerTable"
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Player Per Table
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="playerPerTables"
                        id="playerPerTables"
                        value={formValue.playerPerTables}
                        placeholder="Enter Player Per Table"
                        type="number"
                        max={10000000}
                        min={1}
                        onKeyDown={blockInvalidChar}
                        onChange={onInputChangePlayerPerTable}
                        className="form-control-input"
                      />
                      {!maxValidate.playerPerTables && (
                        <span className="errors">{errors.playerPerTables}</span>
                      )}
                      <span className="errors">
                        {maxValidate.playerPerTables}
                      </span>
                    </div>
                  </div>
                  {/*Registration Start Time*/}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Registration Start Time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <DatePicker
                        showTimeSelect
                        dateFormat="dd-MM-yyyy - HH':'mm "
                        minDate={new Date()}
                        timeFormat="HH:mm"
                        minTime={minTimeRegist}
                        maxTime={setHours(setMinutes(startDate2, 59), 23)}
                        timeIntervals={5}
                        name="registrationBeforeStarttime"
                        placeholderText="Registration Start Time"
                        className="form-control-input date_picker"
                        selected={startDate}
                        onChange={(e) => handleDatePickerRegistration(e)}
                      />
                      <span className="errors">
                        {errors.registrationBeforeStarttime}
                      </span>
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Unregister cut off Time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="unregisterCutOffTime"
                        type="number"
                        max={10000000}
                        min={1}
                        placeholder="Enter Unregister Cut Off Time (sec)"
                        onKeyDown={blockInvalidChar}
                        onChange={onInputChange}
                        className="form-control-input"
                      />
                      <span className="errors">
                        {errors.unregisterCutOffTime}
                      </span>
                    </div>
                  </div>
                  {/* Tournament Start Time */}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Tournament Start Time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <DatePicker
                        showTimeSelect
                        placeholderText="Tournament Start Time"
                        dateFormat="dd-MM-yyyy - HH':'mm "
                        minDate={startDate}
                        selected={startDate2}
                        timeFormat="HH:mm"
                        timeIntervals={5}
                        minTime={minT}
                        maxTime={setHours(setMinutes(startDate2, 59), 23)}
                        name="tournamentStartTime"
                        className="form-control-input date_picker"
                        onChange={(e) => handleDatePickerTournament(e)}
                      />
                      <span className="errors">
                        {errors.tournamentStartTime}
                      </span>
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Blind Structure
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="blindStructureId"
                        className="form-control-input"
                        onChange={onInputChange}
                      >
                        <option hidden>Blind Structure</option>
                        {dataBlindStructure?.map((data: any, index: any) => (
                          <option className="maxw" key={index} value={data._id}>
                            {data.ruleName.substring(0, 40)}
                          </option>
                        ))}
                      </select>
                      <span className="errors">{errors.blindStructureId}</span>
                    </div>
                  </div>
                  {/* Time Bank (sec) */}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Time Bank (sec)
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="timeBank"
                        type="number"
                        max={10000000}
                        min={1}
                        placeholder="Enter time bank (sec)"
                        onKeyDown={blockInvalidChar}
                        onChange={onInputChange}
                        className="form-control-input"
                      />
                      <span className="errors">{errors.timeBank}</span>
                      <span className="errors">{maxValidate.timeBank}</span>
                    </div>
                    <label
                      htmlFor="actionTime"
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Action time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="actionTime"
                        id="actionTime"
                        className="form-control-input"
                        onChange={onInputChange}
                      >
                        <option hidden>Action time</option>
                        {actionTimeArr?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                      <span className="errors">{errors.actionTime}</span>
                    </div>
                  </div>
                  {/* Additional Time Bank */}
                  <div className="form-group flex justify-content-start mt-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Additional Time Bank
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="isAddTimeBank"
                        className="form-control-input"
                        onChange={(e) => {
                          changeSelectType(e);
                          onInputChange(e);
                        }}
                      >
                        <option hidden>Is Additional Time Bank</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <span className="errors">{errors.isAddTimeBank}</span>
                    </div>
                    {isAddTimeBank && (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Select Additional Time Rule
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <select
                            required
                            name="timeBankRuleId"
                            className="form-control-input"
                            onChange={onInputChange}
                          >
                            <option hidden>Additional Time Bank Rule</option>
                            {dataTimeBank?.map((data: any, index: any) => (
                              <option key={index} value={data._id}>
                                {data.timeBankName.substring(0, 40)}
                              </option>
                            ))}
                          </select>
                          <span className="errors">
                            {errors.timeBankRuleId}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {/* Late Registration Allowed */}
                  <div className="form-group flex justify-content-start mt-4 form-group-last pb-4 align-items-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Late Registration Allowed
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        required
                        name="lateRegistrationAllowed"
                        className="form-control-input"
                        onChange={(e) => {
                          changeSelectType(e);
                          onInputChange(e);
                        }}
                      >
                        <option hidden>Late Registration Allowed</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <span className="errors">
                        {errors.lateRegistrationAllowed}
                      </span>
                    </div>
                    {registrationAllowed && (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Late Registration Time(Blind level)
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            required
                            name="lateRegistrationTime"
                            type="number"
                            max={10000000}
                            min={1}
                            onKeyDown={blockInvalidChar}
                            onChange={onInputChange}
                            className="form-control-input"
                          />
                          <span className="errors">
                            {errors.lateRegistrationTime}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {/* SELECT REBUY */}
                  <div className="select-rebuy  form-group-last pb-4">
                    <div className="form-group flex justify-content-start mt-4 align-items-start">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Select Rebuy
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          required
                          name="isRebuyAllowed"
                          className="form-control-input"
                          onChange={(e) => {
                            changeSelectType(e);
                            onInputChange(e);
                          }}
                        >
                          <option hidden>Is Rebuy Allowed</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <span className="errors">{errors.isRebuyAllowed}</span>
                      </div>
                      {selectPrice.isRebuyPrice && (
                        <>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Rebuy Condition(%)
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              onKeyDown={blockInvalidChar}
                              placeholder="Enter rebuy condition"
                              min={1}
                              max={100}
                              onChange={onInputChangeCondition}
                              name="rebuyCondition"
                              className="form-control-input"
                            />
                            <span className="errors">
                              {errors.rebuyCondition}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    {selectPrice.isRebuyPrice && (
                      <>
                        <div className="form-group flex justify-content-start mt-4 align-items-start">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Rebuy Chips
                          </label>

                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              name="rebuyChip"
                              onKeyDown={blockInvalidChar}
                              placeholder="Enter rebuy chip"
                              onChange={onInputChange}
                              className="form-control-input"
                            />
                            <span className="errors">{errors.rebuyChip}</span>
                          </div>
                        </div>

                        <div className="form-group flex justify-content-start mt-4 align-items-start">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12 col-12"
                          >
                            No of Rebuy
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              onKeyDown={blockInvalidChar}
                              name="numberOfRebuy"
                              placeholder="Enter no of rebuy"
                              onChange={onInputChange}
                              className="form-control-input"
                            />
                            <span className="errors">
                              {errors.numberOfRebuy}
                            </span>
                          </div>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Last Rebuy(Blind Level)
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              placeholder="Enter last rebuy"
                              onKeyDown={blockInvalidChar}
                              name="lastRebuy"
                              onChange={onInputChange}
                              className="form-control-input"
                            />
                            <span className="errors">{errors.lastRebuy}</span>
                          </div>
                        </div>
                        <div className="rebuy_Price">
                          <div className="form-group flex justify-content-start mt-4 align-items-start">
                            <label
                              htmlFor=""
                              className="col-md-12 col-lg-12 col-xl-2 col-12 col-12"
                            >
                              Rebuy Price
                            </label>
                            <div className="Real chips text-center">
                              <Form.Check
                                type="switch"
                                id="realChips"
                                label="Real chips"
                                placeholder="Enter real chips"
                                name="rebuyRealChip"
                                value="false"
                                onChange={(e: any) => {
                                  changeSelectType(e);
                                  onInputChange(e);
                                }}
                              />
                            </div>
                          </div>
                          {selectPrice.isRebuyRealChip && (
                            <>
                              <div className="form-group flex justify-content-start mt-4   align-items-start">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Rebuy Amount
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    name="rebuyAmount"
                                    placeholder="Rebuy Amount"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.rebuyAmount}
                                  </span>
                                </div>
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  House Fees(Rebuy)
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    name="rebuyHouseFee"
                                    placeholder="Rebuy HouseFee"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.rebuyHouseFee}
                                  </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    name="rebuyHouseFeeTotal"
                                    disabled
                                    value={
                                      Number(formValue.rebuyAmount) +
                                      Number(formValue.rebuyHouseFee)
                                    }
                                    placeholder="Total Chips"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isRebuyVipPoint && (
                            <>
                              <div className="form-group flex justify-content-start mt-4 align-items-start">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Rebuy Points
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    name="rebuyPoint"
                                    placeholder="Enter rebuy Point"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.rebuyPoint}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isRebuyTicket && (
                            <>
                              <div className="form-group flex justify-content-start mt-4 align-items-start">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Rebuy Tickets
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    name="rebuyTicket"
                                    placeholder="Enter rebuy Ticket"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.rebuyTicket}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {/* SELECT REENTRY */}
                  <div className="select-reentry form-group-last pb-4">
                    <div className="form-group flex justify-content-start mt-4 align-items-start">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Select Reentry
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          required
                          name="isReentryAllowed"
                          className="form-control-input"
                          onChange={(e: any) => {
                            changeSelectType(e);
                            onInputChange(e);
                          }}
                        >
                          <option hidden>Is Reentry Allowed</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <span className="errors">
                          {errors.isReentryAllowed}
                        </span>
                      </div>
                    </div>
                    {selectPrice.isReenTryPrice && (
                      <>
                        <div className="form-group flex justify-content-start mt-4 align-items-start">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Reentry Chips
                          </label>

                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              name="reentryChip"
                              placeholder="Reentry Chips"
                              className="form-control-input"
                              onKeyDown={blockInvalidChar}
                              onChange={onInputChange}
                            />
                            <span className="errors">{errors.rebuyChip}</span>
                          </div>
                        </div>

                        <div className="form-group flex justify-content-start mt-4 align-items-start">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            No of Reentry
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              onKeyDown={blockInvalidChar}
                              name="numberOfReentry"
                              placeholder="No of Reentry"
                              className="form-control-input"
                              onChange={onInputChange}
                            />
                            <span className="errors">
                              {errors.numberOfReentry}
                            </span>
                          </div>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Last Reentry(Blind Level)
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              onKeyDown={blockInvalidChar}
                              name="lastReentry"
                              className="form-control-input"
                              placeholder="Last Reentry(Blind Level)"
                              onChange={onInputChange}
                            />

                            <span className="errors">{errors.lastReentry}</span>
                          </div>
                        </div>
                      </>
                    )}

                    {selectPrice.isReenTryPrice && (
                      <>
                        <div className="reentry_Price">
                          <div className="form-group flex justify-content-start mt-4 align-items-start">
                            <label
                              htmlFor=""
                              className="col-md-12 col-lg-12 col-xl-2 col-12 col-12"
                            >
                              Reentry Price
                            </label>
                            <div className="Real chips text-center">
                              <Form.Check
                                type="switch"
                                id="reentryRealChip"
                                label="Real chips"
                                value="false"
                                name="reentryRealChip"
                                onChange={(e: any) => {
                                  changeSelectType(e);
                                  onInputChange(e);
                                }}
                              />
                            </div>
                          </div>
                          {selectPrice.isReenTryRealChip && (
                            <>
                              <div className="form-group flex justify-content-start mt-4 mb-5   align-items-start">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Reentry Amount
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    placeholder="Reentry Amount"
                                    name="reentryAmount"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.reentryAmount}
                                  </span>
                                </div>
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12 "
                                >
                                  House Fees(Reentry)
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12 ">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    placeholder="Reentry HouseFee"
                                    name="reentryHouseFee"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.reentryHouseFee}
                                  </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    name="reentryHouseFeeTotal"
                                    disabled
                                    value={
                                      Number(formValue.reentryAmount) +
                                      Number(formValue.reentryHouseFee)
                                    }
                                    placeholder="Total Chips"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isReenTryVipPoint && (
                            <>
                              <div className="form-group flex justify-content-start mt-4 align-items-start">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Reentry Points
                                </label>
                                <div className="col-md-12 col-lg-8 col-12 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    onKeyDown={blockInvalidChar}
                                    placeholder="Enter reentry points"
                                    name="reentryPoint"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.reentryPoint}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isReenTryTicket && (
                            <>
                              <div className="form-group flex justify-content-start mt-4 align-items-start">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Reentry Tickets
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    required
                                    type="number"
                                    max={10000000}
                                    min={1}
                                    name="reentryTicket"
                                    placeholder="Enter reentry ticket"
                                    className="form-control-input"
                                    onChange={onInputChange}
                                  />
                                  <span className="errors">
                                    {errors.reentryTicket}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {/* SELECT ADDON */}
                  <div className="select-addon form-group-last pb-4">
                    <div className="form-group flex justify-content-start mt-4 align-items-start">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Select Addon
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          required
                          name="isAddonTimeEnabled"
                          className="form-control-input"
                          onChange={(e) => {
                            changeSelectType(e);
                            onInputChange(e);
                          }}
                        >
                          <option hidden>Is Addon Allowed</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <span className="errors">
                          {errors.isAddonTimeEnabled}
                        </span>
                      </div>
                    </div>
                    {selectPrice.isSelectAddon && (
                      <>
                        <div className="form-group flex justify-content-start mt-4 align-items-start">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            No of AddOn
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                            <input
                              required
                              type="number"
                              min={1}
                              max={100}
                              name="numberOfAddOn"
                              placeholder="No of Addon"
                              value={listCountAddOn}
                              onKeyDown={blockInvalidChar}
                              className="form-control-input"
                              onChange={(e: any) => {
                                handleAddon(e);
                                onInputChange(e);
                              }}
                            />
                            <span className="errors">
                              {errors.numberOfAddOn}
                            </span>
                          </div>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                          >
                            Add On Price
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              onKeyDown={blockInvalidChar}
                              name="addOnPrice"
                              onChange={onInputChange}
                              placeholder="Add On Price"
                              className="form-control-input"
                            />
                            <span className="errors">{errors.addOnPrice}</span>
                          </div>
                          <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                            <input
                              required
                              type="number"
                              max={10000000}
                              min={1}
                              name="addOnChip"
                              onChange={onInputChange}
                              placeholder="Addon chips"
                              onKeyDown={blockInvalidChar}
                              className="form-control-input"
                            />
                            <span className="errors">{errors.addOnChip}</span>
                          </div>
                        </div>
                        {[...Array(+listCountAddOn)]?.map((e, i) => (
                          <div
                            key={i}
                            className="form-group flex justify-content-start mt- align-items-start4"
                          >
                            <label
                              htmlFor=""
                              className="col-md-12 col-lg-12 col-xl-2 col-12"
                            >
                              Add On Time(Blind Level)
                            </label>

                            <div className="col-md-12 col-lg-8 col-12">
                              <input
                                required
                                type="number"
                                max={10000000}
                                min={1}
                                onKeyDown={blockInvalidChar}
                                name={"blindLevel" + i}
                                onChange={handleAddOnChangeValue}
                                className="form-control-input"
                                placeholder="Add On Time(Blind Level)"
                              />

                              <span className="errors">
                                {formValue.lastRebuy &&
                                +formValue.lastRebuy <
                                  +addOn?.[`blindLevel${i}`]
                                  ? "Add On Time must be less than Last Rebuy"
                                  : " "}
                              </span>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>

                  {/* PAYOUT */}
                  <div className="payout">
                    <div className="form-group flex justify-content-start mt-4 align-items-start">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Payout Type
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          required
                          name="payoutType"
                          className="form-control-input"
                          onChange={onInputChange}
                        >
                          <option hidden>Select Payout Type</option>
                          {PayoutType?.map((data, index) => (
                            <option key={index} value={data.value}>
                              {data.title}
                            </option>
                          ))}
                        </select>
                        <span className="errors">{errors.payoutType}</span>
                      </div>
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                      >
                        Select Payout
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          required
                          name="payoutId"
                          className="form-control-input"
                          onChange={onInputChange}
                        >
                          <option hidden>Select Payout</option>
                          {dataPayout?.map((data: any, index: any) => (
                            <option key={index} value={data._id}>
                              {data.payoutName.substring(0, 40)}
                            </option>
                          ))}
                        </select>
                        <span className="errors">{errors.payoutId}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 justify-content-center my-5">
                    <Button
                      type="button"
                      variant="dark"
                      onClick={() => Navigate("/listTournament")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </TabContainer>
  );
};

export default CreateTournament;
