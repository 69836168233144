import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Col, Modal, TabContainer, Accordion, Card, Button, Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Loading from '../../../components/loading/Loading';
import BreadCrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { useForm, useWatch } from 'react-hook-form';
import "./minimanagement.css"
import { createMiniTournament, getListGameMode, getListMap, getMiniTournament, updateMiniTournament, updateStatusScheduling } from '../../../services/MiniManagement.services';
import { MiniTournamentType, SubjectType } from '../../../utils/types/miniTournamentType';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
const DayOfWeek: any = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
}
const TimePickerExample: React.FC = () => {
    const [listSubjectValue, setListSubjectValue] = useState<SubjectType[]>([])
    const [formValue, setFormValue] = useState<MiniTournamentType>()
    const [startDate, setStartDate] = useState<any>();
    const [gameMode, setGameMode] = useState<any>()
    const [map, setMap] = useState<any>()
    const [check, setCheck] = useState<boolean>()
    const [dayOfWeeks, setDayOfWeek] = useState<any>()
    const [status, setStatus] = useState<any>()
    const handleStartDate = (value: any) => {
        setStartDate(value)
    };
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        control,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm<any>({});
    const typeChecked = useWatch({
        name: "type",
        control,
    });
    const gameModeChecked = useWatch({
        name: "gameMode",
        control,
    });

    const scheduleChecked = useWatch({
        name: "radioOption",
        control,
    })

    const handleChangeDayOfWeek = () => {
        const arrDays: string[] = []
        formValue?.schedule?.dayOfWeek?.map((v: any) => {
            const dayName = DayOfWeek[v];
            arrDays.push(dayName)
        })
        return (
            <>
                <input
                    type="text"
                    placeholder="Enter Days Of Week"
                    className="form-control mt-4"
                    {...register("dayOfWeek")}
                    defaultValue={arrDays.map(String).join(", ")}
                    onChange={handleChange}
                />
            </>
        )
    }
    const handleReturnScheduling = (type: any, data: any) => {
        let schedule: any = {}
        const selectedDays = dayOfWeeks?.value
            ?.split(", ")
            ?.map((day: any) => Object.keys(DayOfWeek).find(key => DayOfWeek[key] === day))
        if (type === "once") {
            schedule = {
                type: "once",
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
        } else if (type === "recurring" && scheduleChecked === "weekly") {
            schedule = {
                type: "weekly",
                dayOfWeek: selectedDays.map((str: any) => Number(str)),
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
        } else {
            schedule = {
                type: "daily",
                time: {
                    hours: startDate ? +moment(startDate).format("HH") : formValue?.schedule?.time?.hours,
                    minutes: startDate ? +moment(startDate).format("mm") : formValue?.schedule?.time?.minutes,
                    timezone: moment().format("Z")
                }
            }
        }
        return schedule
    }
    const handleGetName = (Id: any) => {
        const name = map?.map((val: any) => {
            if (val?.mapId === Id) {
                return val.name
            }
        })
        return name.filter((value: any) => value !== undefined);
    }
    const onSubmit = (data: any, e: any) => {
        const type = data?.type ? data?.type : formValue?.type;
        const payload: any = {
            type: type,
            numberOfPlayers: +data?.numberOfPlayers ? +data?.numberOfPlayers : formValue?.numberOfPlayers,
            schedule: handleReturnScheduling(type, data),
            xpReward: [+data?.XP1, +data?.XP2, +data?.XP3],
            gameMode: gameModeChecked ? gameModeChecked : formValue?.gameMode,
            map: {
                name: data?.mapId ? handleGetName(data?.mapId)[0] : formValue?.map?.name,
                mapId: data?.mapId ? data?.mapId : formValue?.map?.mapId
            }
        }
        check ? UpdateMiniManagement.mutate(payload) : CreateMiniManagement.mutate(payload);
    };

    const handleStart = () => {
        UpdateStatusMutation.mutate({
            "status": "start"
        })
    }
    const handleStop = () => {
        UpdateStatusMutation.mutate({
            "status": "stop"
        })
    }
    const { isLoading, refetch } = useQuery(["GET_TOURNAMENT_CONFIG"], () => getMiniTournament(), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (status === 200) {
                setFormValue(data)
                setStatus(data?.status)
                setCheck(true)
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });


    const { isLoading: isLoadingGameMode } = useQuery(["GET_GAME_MODE"], () => getListGameMode(), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (status === 200) {
                setGameMode(data?.listGameMode)
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    })
    const { isLoading: isLoadingMap } = useQuery(["GET_LIST_MAP"], () => getListMap(), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (status === 200) {
                setMap(data?.listMap)
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    })
    const UpdateMiniManagement = useMutation((data: any) => updateMiniTournament(data, formValue?._id ? formValue?._id : ""), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Update Mini Tournament Config Successfully");
                refetch();
            }
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });
    const CreateMiniManagement = useMutation((data: any) => createMiniTournament(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Create Mini Tournament Config Successfully");
            }
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });
    const UpdateStatusMutation = useMutation((data: any) => updateStatusScheduling(data), {
        onSuccess: (res) => {
            toast.success("Update Status Successfull")
            setStatus(res.data.tournament.status)
        },
        onError: (error: any) => {
            toast.error(error.response.data.message)
        },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newSubjectValue: SubjectType[] = [...(listSubjectValue || [])];
        switch (name) {
            case "subjectType1":
                newSubjectValue = [
                    ...newSubjectValue,
                    { subjectName: value, subjectType: "1" }
                ];
                break;
            case "subjectType2":
                newSubjectValue = [
                    ...newSubjectValue,
                    { subjectName: value, subjectType: "2" }
                ];
                break;
            case "subjectType3":
                newSubjectValue = [
                    ...newSubjectValue,
                    { subjectName: value, subjectType: "3" }
                ];
                break;
            case "dayOfWeek":
                setDayOfWeek({ ...dayOfWeeks, value })
                break;
        }
        setListSubjectValue(newSubjectValue);
    };
    return (
        <>
            <TabContainer>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem=" Mini Tournament Report"
                                parentItem=" Mini Tournament Report"
                                parentLink="reportTournament" />
                            <div className="card m-auto custom">
                                <div className="portlet-title">
                                    <h3 className="page-title">Mini Tournament Config</h3>
                                </div>

                                <div className="form-search px-4 py-4 ">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <input
                                                    id="name"
                                                    type="text"
                                                    placeholder="Status"
                                                    className="form-control"
                                                    disabled
                                                    value={status}
                                                />
                                                {/* <select {...register("status")} className="form-control" disabled>
                                                    <option defaultValue={formValue?.status} hidden>{formValue?.status}</option>
                                                    <option value="SCHEDULE_STARTED">SCHEDULE STARTED</option>
                                                    <option value="SCHEDULE_STOPED">SCHEDULE STOPED</option>
                                                </select> */}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Control as="select" defaultValue={formValue?.gameMode} {...register("type")}>
                                                        <option defaultValue={formValue?.status} hidden>{formValue?.type}</option>
                                                        <option value="recurring" selected={formValue?.type === "recurring" ? true : false}>Recurring</option>
                                                        <option value="once" selected={formValue?.type === "once" ? true : false}>Only Once</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                {/* <select {...register("type")} className="form-control" >
                                                    <option defaultValue={formValue?.status} hidden>{formValue?.type}</option>
                                                    <option value="recurring" selected={formValue?.type === "recurring" ? true : false}>Recurring</option>
                                                    <option value="once" selected={formValue?.type === "once" ? true : false}>Only Once</option>
                                                </select> */}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <input
                                                    id="name"
                                                    type="number"
                                                    placeholder="No. Of Player"
                                                    className="form-control"
                                                    {...register("numberOfPlayers")}
                                                    defaultValue={formValue?.numberOfPlayers}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Control as="select" defaultValue={formValue?.gameMode} {...register("gameMode")}>
                                                        {gameMode?.map((g: any) => {
                                                            if (g?.gameModeId === formValue?.gameMode) {
                                                                return (
                                                                    <>
                                                                        <option defaultValue={formValue?.gameMode} hidden>{g.name}</option>
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                        {gameMode?.map((g: any) => {
                                                            return (
                                                                <>
                                                                    <option value={g.gameModeId} selected>{g.name}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-3 mb-4">
                                                <div className='col-12 col-md-6 col-lg-12'>
                                                    {typeChecked === "recurring" ?
                                                        <div className="flex">
                                                            <div className="custom-check">
                                                                <input
                                                                    className="custom-radio-input"
                                                                    type="radio"
                                                                    value="daily"
                                                                    {...register('radioOption')}
                                                                />
                                                                <label className="custom-check-label" style={{ marginLeft: "10px" }}>
                                                                    Daily
                                                                </label>
                                                            </div>
                                                            <div className="custom-check">
                                                                <input
                                                                    className="custom-radio-input"
                                                                    type="radio"
                                                                    value="weekly"
                                                                    {...register('radioOption')}
                                                                />
                                                                <label className="custom-check-label" style={{ marginLeft: "10px" }} >
                                                                    Weekly
                                                                </label>
                                                            </div>
                                                        </div>
                                                        : <></>
                                                    }
                                                    {scheduleChecked === "weekly" ? handleChangeDayOfWeek() : <></>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <DatePicker
                                                    autoComplete="off"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    dateFormat="h:mm aa"
                                                    placeholderText="Start Time"
                                                    name="startTime"
                                                    className="form-control"
                                                    selected={startDate}
                                                    timeIntervals={15}
                                                    onChange={(e) => handleStartDate(e)}
                                                    value={startDate ? startDate : formValue?.schedule?.time
                                                        ? moment()
                                                            .utcOffset(formValue.schedule.time.timezone)
                                                            .set({
                                                                hours: formValue.schedule.time.hours,
                                                                minutes: formValue.schedule.time.minutes
                                                            })
                                                            .format("HH:mm A")
                                                        : ""}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <input
                                                    type="text"
                                                    placeholder="Subject Type 1"
                                                    className="form-control"
                                                    {...register("subjectType1")}
                                                    onChange={handleChange}
                                                    defaultValue={1}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <input
                                                    type="text"
                                                    placeholder="Subject Type 2"
                                                    className="form-control"
                                                    {...register("subjectType2")}
                                                    onChange={handleChange}
                                                    defaultValue={2}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <input
                                                    type="text"
                                                    placeholder="Subject Type 3"
                                                    className="form-control"
                                                    {...register("subjectType3")}
                                                    onChange={handleChange}
                                                    defaultValue={3}

                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-12 mb-4">
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Control as="select" defaultValue={formValue?.map?.mapId} {...register("mapId")}>
                                                        {map?.map((g: any) => {
                                                            if (g?.mapId === formValue?.map?.mapId) {
                                                                return (
                                                                    <>
                                                                        <option defaultValue={formValue?.map?.mapId} hidden>{g.name}</option>
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                        {map?.map((g: any) => {
                                                            return (
                                                                <>
                                                                    <option value={g.mapId} >{g.name}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <div className="row">
                                                    <div className="flex">
                                                        <div className="col-12 col-md-2 col-lg-2 mb-4">
                                                            <label style={{ fontWeight: "600" }}>XP Reward</label>
                                                        </div>
                                                        {formValue && formValue?.xpReward?.map((val: any, i: any) => {
                                                            return (
                                                                <div className="col-12 col-md-3 col-lg-3 mb-4">
                                                                    <input
                                                                        required
                                                                        type="number"
                                                                        placeholder={`XP Reward LV${i + 1}`}
                                                                        className="form-control"
                                                                        {...register(`XP${i + 1}`)}
                                                                        defaultValue={val}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="button-group center-item mt-4">
                                            {check
                                                ?
                                                <Button variant="primary" type="submit" className="mx-3">
                                                    Update
                                                </Button>
                                                :
                                                <Button variant="primary" type="submit" className="mx-3">
                                                    Submit
                                                </Button>
                                            }
                                        </div>
                                    </form>
                                    <div className="button-group flex mt-4">
                                        <Button variant="secondary" type="button" onClick={handleStart}>
                                            Start Scheduling
                                        </Button>
                                        <Button variant="danger" type="button" onClick={handleStop} >
                                            Stop Scheduling
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                )}
            </TabContainer>
        </>

    );
};

export default TimePickerExample;
