import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import "./dashboard.css";
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 600);
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="m-auto">
          <div className="note">
            <h3>Welcome to Triviador Question Managenent</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
