import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Modal, TabContainer } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../components/loading/Loading";
import { encryptionEnable } from "../../config/config";
import tournamentServices from "../../services/tournament.services";
import "../../styles/listtournament.css";
import * as ulti from "../../utils/decrypt/index";
import {
  blockInvalidChar,
  preventSerachChange,
} from "../../utils/validation/validation";
const gameVariationArr = [
  {
    id: 1,
    title: "NL Holdem",
    value: "NLH",
  },
  {
    id: 2,
    title: "Omaha",
    value: "PLO",
  },
];
const tournamentTypeArr = [
  {
    id: 1,
    title: "Free Roll",
    value: "freeRoll",
  },
  {
    id: 2,
    title: "Normal",
    value: "normal",
  },
  {
    id: 3,
    title: "Satellite",
    value: "satellite",
  },
];
const arrayTrueFalse = [
  {
    id: 1,
    title: "Yes",
    value: "true",
  },
  {
    id: 2,
    title: "No",
    value: "false",
  },
];
const initFormValue = {
  gameType: "",
  playerNumber: "",
  tournamentId: "",
  tournamentType: "",
  isReentryAllowed: "",
  isRebuyAllowed: "",
  isAddonTimeEnabled: "",
  isGtdEnabled: "",
  state: "",
};
const PER_PAGE = 10;
const ListTournament = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [formValue, setFormValue] = useState<any>(initFormValue);
  const [dataTournament, setDataTournament] = useState<any | null>([]);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [pageNumber, setPageNumber] = useState(0);
  const [showPublished, setShowPublished] = useState(false);
  const [idPublished, setIdPublished] = useState(false);
  const [timeStartPublish, setTimeStartPublish] = useState<any>();
  const [id, setId] = useState("");
  useEffect(() => {
    initDataListTournament(1);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  const handleShow = (id: any) => {
    setId(id);
    setShow(true);
  };

  const handlePageChange = ({ selected }: any) => {
    setPageNumber(selected);
    initDataListTournament(selected + 1);
  };
  const handleShowCancel = (id: any) => {
    setShowCancel(true);
    setId(id);
  };
  const handleClose = () => {
    setShow(false);
    setShowCancel(false);
  };
  const formatTimeMoment = (time: any) => {
    const format1 = "DD-MM-YYYY HH:mm";
    const timeFormat = moment(time).format(format1);
    return timeFormat;
  };

  const initDataListTournament = (page: number, reset: any = false) => {
    let filterPost = formValue;
    if (reset) {
      filterPost = initFormValue;
    }
    tournamentServices
      .getAllPagination(filterPost, page, PER_PAGE)
      .then(async (data: any) => {
        if (data.data.status === "success") {
          setTotalCount(data.data.totalCount);
          if (encryptionEnable) {
            if (data.data.data) {
              ulti.decrypt(data.data.data).then((listTournament) => {
                setDataTournament(listTournament);
              });
            } else {
              setDataTournament([]);
            }
          } else {
            let listTournament = data.data.data;
            setDataTournament(listTournament);
          }
        } else {
          toast.error("Get Data Failed, No items found");
        }
      });
  };

  const onInputChange = (e: any) => {
    let { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  const handleStartDate = (value: any) => {
    setStartDate(value);
    setFormValue({ ...formValue, startTime: value });
  };
  const handleEndDate = (value: any) => {
    setEndDate(value);
    setFormValue({ ...formValue, endTime: value });
  };
  const handleSearch = () => {
    initDataListTournament(0);
  };
  const handleResetFormSearch = () => {
    setFormValue(initFormValue);
    setPageNumber(0);
    setStartDate("");
    setEndDate("");
    initDataListTournament(1, true);
  };
  const handleDelete = () => {
    tournamentServices.deleteOne(id).then((res: any) => {
      if (res.status === 200) {
        initDataListTournament(1);
        toast.success("Deleted Successfully");
        setShow(false);
      } else {
        toast.error("Delete Failed");
        setShow(false);
      }
    });
  };
  const padTo2Digits = (num: any) => {
    return num.toString().padStart(2, "0");
  };
  const formatDate = (date: any) => {
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
    );
  };
  const handleCancel = () => {
    tournamentServices.cancel(id).then((res: any) => {
      if (res.status === 200) {
        initDataListTournament(1);
        toast.success("Cancel Successfully");
        setShowCancel(false);
        initDataListTournament(pageNumber + 1);
      } else {
        toast.error("Cancel Failed");
        setShowCancel(false);
      }
    });
  };

  const handlePublishStatus = (id: string | any) => {
    let timeStart: number = moment(timeStartPublish).valueOf();
    let dateNow: number = new Date().getTime();
    if (dateNow - timeStart > 0) {
      toast.warn("Publishing time has expired");
    } else if (id) {
      tournamentServices.changeStatus(id).then((res: any) => {
        if (res.data.status === "Change Status Successfully") {
          toast.success("Change publish Successfully");
          initDataListTournament(pageNumber + 1);
        } else {
          toast.error("Change publish Failed");
        }
      });
    }
  };

  const handleConfirmPublished = (id: any, timeStart: any) => {
    setTimeStartPublish(timeStart);
    setIdPublished(id);
    setShowPublished(true);
  };

  const handleClosePublished = () => {
    setShowPublished(false);
  };

  const handlePublished = () => {
    handlePublishStatus(idPublished);
    setShowPublished(false);
  };
  const totalPage = Math.ceil(totalCount / PER_PAGE);
  const displayData = dataTournament?.map((data: any, index: any) => {
    return (
      <tr key={index} className="trTournament">
        <td>{index + PER_PAGE * pageNumber + 1}</td>
        <td className="hidden-name-table">{data.tournamentName}</td>
        <td>{data.tournamentId}</td>
        <td>{formatTimeMoment(data.tournamentStartTime)}</td>
        <td>{formatTimeMoment(data.createdAt)}</td>
        <td>{data.gameVariation}</td>
        <td>{data.guaranteedValue ? data.guaranteedValue : "0"}</td>
        <td>{data.tournamentType}</td>
        <td>{data?.gameVariation}</td>
        <td>{data?.state?.toUpperCase()}</td>
        <td>
          <div className="d-flex flex-nowrap gap-1 align-items-center">
            {data?.gameState === "UPCOMING" && (
              <button
                type="button"
                className="btn-custom  btn-tournament btn-info mx-1"
                onClick={() =>
                  handleConfirmPublished(data._id, data.tournamentStartTime)
                }
              >
                Publish
              </button>
            )}
            {data?.gameState === "UPCOMING" && (
              <button
                type="button"
                className="btn-custom btn-edit btn-tournament mx-1"
                onClick={() => Navigate("/editTournament/" + data._id)}
              >
                Edit
              </button>
            )}

            <button
              className="btn-secondary btn-custom btn-tournament "
              onClick={() => Navigate("/viewTournament/" + data._id)}
            >
              View
            </button>
            <button
              className="btn-success btn-custom  btn-tournament"
              onClick={() => Navigate("/duplicateTournament/" + data._id)}
            >
              Duplicate
            </button>
            {data?.gameState === "UPCOMING" && (
              <>
                <button
                  className="btn-danger btn-custom mx-1 btn-tournament"
                  onClick={() => handleShow(data._id)}
                >
                  Delete
                </button>
                <button
                  className="btn-danger btn-custom  btn-tournament"
                  onClick={() => handleShowCancel(data._id)}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  });
  return (
    <>
      <TabContainer>
        {loading ? (
          <Loading />
        ) : (
          <div className="list-tournament pb-5">
            <Col>
              <BreadCrumbs childItem="List Tournament" />
              <div className="card m-auto">
                <div className="portlet-title">
                  <h3 className="page-title">List Tournament</h3>
                </div>
                <div className="search-form mt-5">
                  <div className="form">
                    <form className="">
                      <div className="d-flex justify-content-center flex-wrap">
                        <div className="col-3 col-3-custom mx-2 my-2">
                          <DatePicker
                            showTimeSelect
                            dateFormat="dd-MM-yyyy - HH':'mm "
                            placeholderText={`${formatDate(new Date())}`}
                            name="startTime"
                            onKeyDown={preventSerachChange}
                            className="form-custom-input date_picker date-list w-100"
                            selected={startDate}
                            onChange={(e) => handleStartDate(e)}
                          />
                        </div>
                        <div className="col-3 col-3-custom mx-2 my-2">
                          <DatePicker
                            showTimeSelect
                            dateFormat="dd-MM-yyyy - HH':'mm "
                            minDate={startDate}
                            name="endTime"
                            onKeyDown={preventSerachChange}
                            placeholderText={`${formatDate(new Date())}`}
                            className="form-custom-input date_picker date-list w-100"
                            selected={endDate}
                            onChange={(e) => handleEndDate(e)}
                          />
                        </div>
                        <div className=" col-4 col-md-3 col-lg-2 mx-2 my-2">
                          <input
                            type="text"
                            onKeyDown={preventSerachChange}
                            name="tournamentId"
                            className="form-custom-input search"
                            placeholder="Tournament ID"
                            value={formValue.tournamentId}
                            onChange={onInputChange}
                          />
                        </div>
                        <div className=" col-4 col-md-4 col-lg-2 mx-2 my-2">
                          <select
                            className="form-custom-input search"
                            name="gameType"
                            onChange={onInputChange}
                          >
                            <option hidden>Game Type</option>
                            {gameVariationArr?.map((data, index) => (
                              <option key={index} value={data.value}>
                                {data.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" col-4 col-md-4 col-lg-2 mx-2 my-2">
                          <input
                            type="number"
                            onKeyDown={blockInvalidChar}
                            name="playerNumber"
                            value={formValue.playerNumber}
                            className="form-custom-input search"
                            placeholder="No. Of Player"
                            min={1}
                            onChange={onInputChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center flex-wrap">
                        <div className=" col-3 col-md-3 col-lg-3 col-3-custom mx-2 my-2">
                          <select
                            className="form-custom-input search"
                            name="tournamentType"
                            value={formValue.tournamentType}
                            onChange={onInputChange}
                          >
                            <option hidden>Tournament Type</option>
                            {tournamentTypeArr?.map((data, index) => (
                              <option key={index} value={data.value}>
                                {data.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                          <select
                            className="form-custom-input search"
                            name="isReentryAllowed"
                            onChange={onInputChange}
                            value={formValue.isReentryAllowed}
                          >
                            <option hidden>Re-Entry</option>
                            {arrayTrueFalse?.map((data, index) => (
                              <option key={index} value={data.value}>
                                {data.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                          <select
                            className="form-custom-input search"
                            name="isRebuyAllowed"
                            onChange={onInputChange}
                            value={formValue.isRebuyAllowed}
                          >
                            <option hidden>Re-Buy</option>
                            {arrayTrueFalse?.map((data, index) => (
                              <option key={index} value={data.value}>
                                {data.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                          <select
                            className="form-custom-input search"
                            name="isAddonTimeEnabled"
                            onChange={onInputChange}
                            value={formValue.isAddonTimeEnabled}
                          >
                            <option hidden>Add-On</option>
                            {arrayTrueFalse?.map((data, index) => (
                              <option key={index} value={data.value}>
                                {data.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" col-3 col-md-3 mx-2 my-2 col-lg-3 col-3-custom">
                          <select
                            className="form-custom-input search"
                            name="isGtdEnabled"
                            onChange={onInputChange}
                            value={formValue.isGtdEnabled}
                          >
                            <option hidden>Guaranteed</option>
                            {arrayTrueFalse?.map((data, index) => (
                              <option key={index} value={data.value}>
                                {data.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" col-3 mx-2 my-2 col-lg-3 col-3-custom">
                          <select
                            className="form-custom-input search"
                            name="state"
                            onChange={onInputChange}
                            value={formValue.state}
                          >
                            <option hidden>Game State</option>
                            <option value="REGISTER">REGISTER</option>
                            <option value="RUNNING">RUNNING</option>
                            <option value="PUBLISHED">PUBLISHED</option>
                            <option value="UPCOMING">UPCOMING</option>
                            <option value="CLOSED">CLOSED</option>
                            <option
                              value="Open To Register"
                              className="text-uppercase"
                            >
                              Open To Register
                            </option>
                            <option
                              value="Registration Freezed"
                              className="text-uppercase"
                            >
                              Registration Freezed
                            </option>
                            <option
                              value="Open To Late Register"
                              className="text-uppercase"
                            >
                              Open To Late Register
                            </option>
                            <option value="CANCELED">CANCELED</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex justify-content-center mt-5">
                        <button
                          type="button"
                          className="btn-custom btn-primary btn-search"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="btn-custom btn-primary btn-reset"
                          onClick={handleResetFormSearch}
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="table-data ">
                  <table className="table table-bordered mt-5">
                    <thead>
                      <tr className="trTournament">
                        <th>S.No.</th>
                        <th>Tournament Name</th>
                        <th>Tournament ID</th>
                        <th>Start At</th>
                        <th>Created At</th>
                        <th>Game</th>
                        <th>Prize Pool</th>
                        <th>Tournament Type</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>{displayData}</tbody>
                  </table>
                </div>
              </div>
            </Col>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              forcePage={pageNumber}
              pageCount={totalPage}
              onPageChange={handlePageChange}
              activeClassName={"page-item active"}
              disabledClassName={"page-item disabled"}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              pageLinkClassName={"page-link"}
            />
          </div>
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete ?</Modal.Body>
          <Modal.Footer>
            <button className="btn-custom btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button className="btn-custom btn-danger" onClick={handleDelete}>
              Remove
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={showCancel} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to cancel ?</Modal.Body>
          <Modal.Footer>
            <button className="btn-custom btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button className="btn-custom btn-danger" onClick={handleCancel}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showPublished} onHide={handleClosePublished}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to publish ?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn-custom btn-secondary"
              onClick={handleClosePublished}
            >
              Close
            </button>
            <button className="btn-custom btn-info" onClick={handlePublished}>
              Publish
            </button>
          </Modal.Footer>
        </Modal>
      </TabContainer>
    </>
  );
};
export default ListTournament;
