import { useEffect, useState } from "react";
import { Col, TabContainer } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../components/loading/Loading";
import { encryptionEnable } from "../../config/config";
// import blindStructureService from "../../services/blindStructure.services";
import payoutStructure from "../../services/PayoutStructure.services";
// import timeBankService from "../../services/timeBank.services";
import tournamentServices from "../../services/tournament.services";
import "../../styles/createtournament.css";
import * as ulti from "../../utils/decrypt/index";

const gameVariationArr = [
  {
    id: 1,
    title: "NLH Holdem",
    value: "NLH",
  },
  {
    id: 2,
    title: "Omaha",
    value: "PLO",
  },
];

const chipsTypeArr = [
  {
    id: 1,
    title: "Real",
    value: true,
  },
  {
    id: 2,
    title: "Play",
    value: false,
  },
];

const PayoutType = [
  {
    id: 1,
    title: "Chips",
    value: "chips",
  },
  {
    id: 2,
    title: "Tickets",
    value: "ticket",
  },
];
const tournamentTypeArr = [
  {
    id: 1,
    title: "Free Roll",
    value: "freeRoll",
  },
  {
    id: 2,
    title: "Normal",
    value: "normal",
  },
  {
    id: 3,
    title: "Satellite",
    value: "satellite",
  },
];

const actionTimeArr = [
  {
    id: 1,
    title: "Standard(30 sec)",
    value: "30",
  },
  {
    id: 2,
    title: "Turbo(20 sec)",
    value: "20",
  },
  {
    id: 3,
    title: "Hyper Turbo(10 sec)",
    value: "10",
  },
];
const initDataFormValue = {
  actionTime: "",
  addOnChip: "",
  addOnPrice: "",
  chipType: "",
  entryFees: "",
  gameVariation: "",
  houseFees: "",
  isAddTimeBank: "",
  isAddonTimeEnabled: "",
  isRebuyAllowed: "",
  isRebuyTicket: "",
  isReentryAllowed: "",
  isReentryTicket: "",
  lastRebuy: "",
  lastReentry: "",
  lateRegistrationAllowed: "",
  lateRegistrationTime: "",
  maxPlayersAllowed: "",
  rebuyRealChip: "",
  rebuyChip: "",
  rebuyPoint: "",
  rebuyTicket: "",
  reentryChip: "",
  reentryTicket: "",
  payoutId: "",
  minPlayersToStart: "",
  numberOfAddOn: "",
  numberOfRebuy: "",
  numberOfReentry: "",
  blindStructureId: "",
  payoutType: "",
  playerPerTables: "",
  rebuyAmount: "",
  rebuyHouseFee: "",
  reentryAmount: "",
  reentryHouseFee: "",
  startingChips: "",
  guaranteedValue: "",
  timeBank: "",
  totalEntryFees: "",
  totalRebuyChip: "",
  totalreentryChip: "",
  tournamentName: "",
  tournamentType: "",
  isGtdEnabled: "",
  rebuyHouseFeeTotal: "",
  rebuyCondition: "",
  unregisterCutOffTime: "",
  timeBankRuleId: "",
  maxPlayersToStart: "",
  reentryPoint: "",
  registrationBeforeStarttime: "",
  tournamentStartTime: "",
  rebuyVipPoint: "",
  reentryHouseFeeTotal: "",
  isRebuyPrice: "",
  isRebuyRealChip: "",
  isRebuyVipPoint: "",
  isReenTryPrice: "",
  isReenTryRealChip: "",
  isReenTryVipPoint: "",
  reentryVipPoint: "",
  isReenTryTicket: "",
  isSelectAddon: "",
  reentryRealChip: "",
  addOnTime: {},
};
const initIsRebuyPrice = {
  isRebuyPrice: false,
  isRebuyRealChip: false,
  isRebuyVipPoint: false,
  isRebuyTicket: false,
  isReenTryPrice: false,
  isReenTryRealChip: false,
  isReenTryVipPoint: false,
  isReenTryTicket: false,
  isSelectAddon: false,
};

const ViewTournament = () => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState<any | null>(null);
  const [startDate2, setStartDate2] = useState<any | null>(null);
  const [formValue, setFormValue] = useState<any>(initDataFormValue);
  const [dataBlindStructure, setDataBlindStructure] = useState([]);
  const [dataTimeBank, setDataTimeBank] = useState([]);
  const [listCountAddOn, setListCountAddOn] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleGuaranteed, setToggleGuaranteed] = useState(false);
  const [isAddTimeBank, setAddIsTimeBank] = useState(false);
  const [registrationAllowed, setRegistrationAllowed] = useState(false);
  const [dataPayout, setDataPayout] = useState([]);
  const [selectPrice, setSelectPrice] = useState<any>(initIsRebuyPrice);
  const [toggleTournamentType, setToggleTournamentType] = useState(false);

  useEffect(() => {
    initOneDataTournament();
    initDataSelect();
  }, []);

  const initDataSelect = async () => {
    // const blind = await blindStructureService.getAll();
    // if (blind.data.status === "success") {
    //   if (encryptionEnable) {
    //     if (blind.data.data) {
    //       ulti.decrypt(blind.data.data).then((listBlind) => {
    //         setDataBlindStructure(listBlind);
    //       });
    //     }
    //   } else {
    //     setDataBlindStructure(blind.data.data);
    //   }
    // }
    // const timeBank = await timeBankService.getAll();
    // if (timeBank.status === 200) {
    //   if (encryptionEnable) {
    //     if (timeBank.data.data) {
    //       ulti.decrypt(timeBank.data.data).then((timeBank) => {
    //         setDataTimeBank(timeBank);
    //       });
    //     }
    //   } else {
    //     setDataTimeBank(timeBank.data.data);
    //   }
    // }
    const payout = await payoutStructure.getAll();
    if (payout.data.status === "success") {
      if (encryptionEnable) {
        if (payout.data.data) {
          ulti.decrypt(payout.data.data).then((payout) => {
            setDataPayout(payout);
          });
        }
      } else {
        setDataPayout(payout.data.data);
      }
    }
  };
  const initOneDataTournament = () => {
    tournamentServices.getOneNotNested(id).then((res) => {
      const dataOneTournament = res.data.data;
      const status = res.data.status;
      if (status === "success") {
        if (encryptionEnable) {
          if (dataOneTournament) {
            ulti.decrypt(dataOneTournament).then((viewTournamentValue) => {
              let viewTournament = {
                ...viewTournamentValue[0],
                blindStructureId: viewTournamentValue[0].blindRuleId,
              };

              formatDateTime(viewTournament);
              setFormValue(viewTournament);
              setToggleGuaranteed(viewTournament.isGtdEnabled);
              setAddIsTimeBank(viewTournament.isAddTimeBank);
              setRegistrationAllowed(viewTournament.lateRegistrationAllowed);
              if (
                viewTournament.tournamentType === "normal" ||
                viewTournament.tournamentType === "satellite"
              ) {
                setToggleTournamentType(true);
              } else if (viewTournament.tournamentType === "freeRoll") {
                setToggleTournamentType(false);
              }
              const dataSelect = {
                isRebuyPrice: viewTournament.isRebuyAllowed,
                isRebuyRealChip: viewTournament.rebuyRealChip,
                isRebuyVipPoint: viewTournament.rebuyVipPoint,
                isRebuyTicket: viewTournament.isRebuyTicket,
                isReenTryPrice: viewTournament.isReentryAllowed,
                isReenTryRealChip: viewTournament.reentryRealChip,
                isReenTryVipPoint: viewTournament.reentryVipPoint,
                isReenTryTicket: viewTournament.isReenTryTicket,
                isSelectAddon: viewTournament.isAddonTimeEnabled,
              };
              setSelectPrice(dataSelect);
              setListCountAddOn(viewTournament.numberOfAddOn);
            });
          }
        } else {
          let data = {
            ...dataOneTournament[0],
            blindStructureId: dataOneTournament[0].blindRuleId,
          };
          formatDateTime(data);
          setFormValue(data);
          setToggleGuaranteed(data.isGtdEnabled);
          setAddIsTimeBank(data.isAddTimeBank);
          setRegistrationAllowed(data.lateRegistrationAllowed);
          if (
            data.tournamentType === "normal" ||
            data.tournamentType === "satellite"
          ) {
            setToggleTournamentType(true);
          } else if (data.tournamentType === "freeRoll") {
            setToggleTournamentType(false);
          }
          const dataSelect = {
            isRebuyPrice: data.isRebuyAllowed,
            isRebuyRealChip: data.rebuyRealChip,
            isRebuyVipPoint: data.rebuyVipPoint,
            isRebuyTicket: data.isRebuyTicket,
            isReenTryPrice: data.isReentryAllowed,
            isReenTryRealChip: data.reentryRealChip,
            isReenTryVipPoint: data.reentryVipPoint,
            isReenTryTicket: data.isReenTryTicket,
            isSelectAddon: data.isAddonTimeEnabled,
          };
          setSelectPrice(dataSelect);
          setListCountAddOn(data.numberOfAddOn);
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  const formatDateTime = (data: any) => {
    const registrationBeforeStarttime = new Date(
      data.registrationBeforeStarttime
    );

    const tournamentStartTime = new Date(data.tournamentStartTime);
    setStartDate(registrationBeforeStarttime);
    setStartDate2(tournamentStartTime);
  };

  const handleDatePickerRegistration = (e: any) => {
    setStartDate(e);
    setFormValue({
      ...formValue,
      registrationBeforeStarttime: e,
    });
  };
  const handleDatePickerTournament = (e: any) => {
    setStartDate2(e);
    setFormValue({
      ...formValue,
      tournamentStartTime: e,
    });
  };
  //
  return (
    <TabContainer>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank  pb-5">
          <Col>
            <BreadCrumbs childItem="View Tournament" />
            <div className="card m-auto text-center pb-5">
              <h3 className="page-title text-center">View Tournament</h3>
              <div className="body-form">
                <form>
                  {/*Tournament Name */}
                  <div className="form-group flex justify-content-start">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Tournament Name
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        disabled
                        name="tournamentName"
                        type="text"
                        value={formValue.tournamentName}
                        placeholder="Enter Tournament Name"
                        className="form-control-input"
                      />
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Game Variation
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="gameVariation"
                        className="form-control-input"
                        value={formValue.gameVariation}
                      >
                        <option hidden>Game Variation</option>
                        {gameVariationArr?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/*Tournament Type */}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Tournament Type
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="tournamentType"
                        className="form-control-input"
                        value={formValue.tournamentType}
                      >
                        <option hidden>Tournament Type</option>
                        {tournamentTypeArr?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <label
                      htmlFor="chipsType"
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Chips Type
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="chipType"
                        id="chipsType"
                        className="form-control-input"
                        value={formValue.chipType}
                      >
                        <option hidden>Game Variation</option>
                        {chipsTypeArr?.map((data: any, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </div>
                  {/*Guaranteed Tournament*/}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor="guaranteedTournament"
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Guaranteed Tournament
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="isGtdEnabled"
                        id="isGtdEnabled"
                        className="form-control-input"
                        value={formValue.isGtdEnabled}
                      >
                        <option hidden>Guaranteed Tournament</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                    {toggleGuaranteed && (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Guaranteed Value
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            disabled
                            name="guaranteedValue"
                            type="number"
                            min={0}
                            className="form-control-input"
                            value={formValue.guaranteedValue}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {/* Entry Fee & House Fee */}
                  {toggleTournamentType && (
                    <>
                      <div className="form-group flex justify-content-start mt-4">
                        <label className="col-md-12 col-lg-12 col-xl-2 col-12">
                          Entry Fees
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            disabled
                            name="entryFees"
                            type="number"
                            value={+formValue.entryFees || ""}
                            min={0}
                            className="form-control-input"
                          />
                        </div>
                        <label
                          htmlFor="playerPerTable"
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          House Fees
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            disabled
                            name="houseFees"
                            type="number"
                            value={+formValue.houseFees || ""}
                            min={0}
                            className="form-control-input"
                          />
                        </div>
                      </div>
                      <div className="form-group flex justify-content-start mt-4">
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12"
                        >
                          Total Entry Fees
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            disabled
                            name="totalEntryFees"
                            type="number"
                            value={
                              +formValue?.entryFees + +formValue?.houseFees
                            }
                            className="form-control-input"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Starting Chips
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            disabled
                            name="startingChips"
                            type="number"
                            min={0}
                            className="form-control-input"
                            value={formValue.startingChips}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/*Action time*/}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 "
                    >
                      Min Players to Start
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        disabled
                        name="minPlayersToStart"
                        type="number"
                        min={0}
                        value={formValue.minPlayersToStart}
                        className="form-control-input"
                      />
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Max Players At Start
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        required
                        name="maxPlayersAtStart"
                        placeholder="Enter max player at start"
                        type="number"
                        disabled
                        max={10000000}
                        value={formValue?.maxPlayersAtStart}
                        className="form-control-input"
                      />

                      {/* <span className="errors">{errors.maxPlayersAtStart}</span> */}
                    </div>
                  </div>
                  {/* Max Players Allowed */}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor="maxPlayersAllowed"
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Max Players Allowed
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        disabled
                        id="maxPlayersAllowed"
                        name="maxPlayersAllowed"
                        type="number"
                        value={formValue.maxPlayersAllowed}
                        min={0}
                        className="form-control-input"
                      />
                    </div>
                    <label
                      htmlFor="playerPerTable"
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Player Per Table
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        disabled
                        name="playerPerTables"
                        id="playerPerTables"
                        type="number"
                        min={0}
                        value={formValue.playerPerTables}
                        className="form-control-input"
                      />
                    </div>
                  </div>
                  {/*Registration Start Time*/}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Registration Start Time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <DatePicker
                        showTimeSelect
                        disabled
                        dateFormat="dd/MM/yyyy - HH':'mm "
                        minDate={new Date()}
                        name="registrationBeforeStarttime"
                        className="form-control-input date_picker"
                        selected={startDate}
                        onChange={(e) => handleDatePickerRegistration(e)}
                      />
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Unregister cut off Time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <input
                        disabled
                        name="unregisterCutOffTime"
                        min={0}
                        type="number"
                        value={formValue.unregisterCutOffTime}
                        className="form-control-input"
                      />
                    </div>
                  </div>
                  {/* Tournament Start Time */}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Tournament Start Time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <DatePicker
                        showTimeSelect
                        disabled
                        dateFormat="dd/MM/yyyy - HH':'mm "
                        minDate={startDate}
                        selected={startDate2}
                        name="tournamentStartTime"
                        className="form-control-input date_picker"
                        onChange={(e) => handleDatePickerTournament(e)}
                      />
                    </div>
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                    >
                      Blind Structure
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="blindStructureId"
                        className="form-control-input"
                        value={formValue.blindStructureId}
                      >
                        <option hidden>Blind Structure</option>
                        {dataBlindStructure?.map((data: any, index: any) => (
                          <option key={index} value={data._id}>
                            {data.ruleName.substring(0, 40)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Time Bank (sec) */}
                  <div className="form-group flex justify-content-start mt-4">
                    {formValue.tournamentType === "freeRoll" ? (
                      ""
                    ) : (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12"
                        >
                          Time Bank (sec)
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12 input-control">
                          <input
                            disabled
                            name="timeBank"
                            type="number"
                            min={0}
                            value={formValue.timeBank}
                            className="form-control-input"
                          />
                        </div>
                      </>
                    )}

                    <label
                      htmlFor="actionTime"
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Action time
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="actionTime"
                        id="actionTime"
                        className="form-control-input"
                        value={formValue.actionTime}
                      >
                        <option hidden> Action time</option>
                        {actionTimeArr?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Additional Time Bank */}
                  <div className="form-group flex justify-content-start mt-4">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Additional Time Bank
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="isAddTimeBank"
                        className="form-control-input"
                        value={formValue.isAddTimeBank}
                      >
                        <option hidden>Is Additional Time Bank</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                    {isAddTimeBank && (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Select Additional Time Rule
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <select
                            disabled={true}
                            name="timeBankRuleId"
                            className="form-control-input"
                            value={formValue.timeBankRuleId}
                          >
                            <option hidden>Additional Time Bank Rule</option>
                            {dataTimeBank?.map((data: any, index: any) => (
                              <option key={index} value={data._id}>
                                {data.timeBankName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                  {/* Late Registration Allowed */}
                  <div className="form-group flex justify-content-start mt-4 form-group-last pb-4">
                    <label
                      htmlFor=""
                      className="col-md-12 col-lg-12 col-xl-2 col-12"
                    >
                      Late Registration Allowed
                    </label>
                    <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                      <select
                        disabled={true}
                        name="lateRegistrationAllowed"
                        className="form-control-input"
                        value={formValue.lateRegistrationAllowed}
                      >
                        <option hidden>Late Registration Allowed</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                    {registrationAllowed && (
                      <>
                        <label
                          htmlFor=""
                          className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                        >
                          Late Registration Time(Blind level)
                        </label>
                        <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                          <input
                            disabled
                            name="lateRegistrationTime"
                            type="number"
                            min={0}
                            value={formValue.lateRegistrationTime}
                            className="form-control-input"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {/* SELECT REBUY */}
                  <div className="select-rebuy  form-group-last pb-4">
                    <div className="form-group flex justify-content-start mt-4">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Select Rebuy
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          disabled={true}
                          name="isRebuyAllowed"
                          className="form-control-input"
                          value={formValue.isRebuyAllowed}
                        >
                          <option hidden>Is Rebuy Allowed</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      {selectPrice.isRebuyPrice && (
                        <>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                          >
                            Rebuy Condition(%)
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              disabled
                              type="number"
                              name="rebuyCondition"
                              value={formValue.rebuyCondition}
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {selectPrice.isRebuyPrice && (
                      <>
                        <div className="form-group flex justify-content-start mt-4">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Rebuy Chips
                          </label>
                          <div className="col-md-12 col-lg-8 col-12">
                            <input
                              disabled
                              type="number"
                              name="rebuyChip"
                              value={formValue.rebuyChip}
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                        </div>

                        <div className="form-group flex justify-content-start mt-4">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            No of Rebuy
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              disabled
                              type="number"
                              name="numberOfRebuy"
                              value={formValue.numberOfRebuy}
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                          >
                            Last Rebuy(Blind Level)
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              disabled
                              type="number"
                              name="lastRebuy"
                              value={formValue.lastRebuy}
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                        </div>
                        <div className="rebuy_Price">
                          <div className="form-group flex justify-content-start mt-4">
                            <label
                              htmlFor=""
                              className="col-md-12 col-lg-12 col-xl-2 col-12"
                            >
                              Rebuy Price
                            </label>
                            <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                              <Form.Check
                                type="switch"
                                id="realChips"
                                label="Real chips"
                                disabled
                                name="rebuyRealChip"
                                value="true"
                                defaultChecked={formValue.rebuyRealChip}
                              />
                            </div>
                            {/* <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                              <Form.Check
                                type="switch"
                                id="vipPoint"
                                label="VIP Points"
                                name="rebuyVipPoint"
                                value="true"
                                defaultChecked={formValue.rebuyVipPoint}
                              />
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                              <Form.Check
                                type="switch"
                                id="Tickets"
                                label="Tickets"
                                value="true"
                                defaultChecked={formValue.isRebuyTicket}
                                name="isRebuyTicket"
                              />
                            </div> */}
                          </div>
                          {selectPrice.isRebuyRealChip && (
                            <>
                              <div className="form-group flex justify-content-start mt-4">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Rebuy Amount
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="rebuyAmount"
                                    value={formValue.rebuyAmount}
                                    className="form-control-input"
                                    min={0}
                                  />
                                </div>
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                                >
                                  House Fees(Rebuy)
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3">
                                  <input
                                    disabled
                                    type="number"
                                    name="rebuyHouseFee"
                                    value={formValue.rebuyHouseFee}
                                    className="form-control-input"
                                    min={0}
                                  />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="rebuyHouseFeeTotal"
                                    value={
                                      Number(formValue.rebuyAmount) +
                                      Number(formValue.rebuyHouseFee)
                                    }
                                    placeholder="Total Chips"
                                    className="form-control-input"
                                    min={0}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isRebuyVipPoint && (
                            <>
                              <div className="form-group flex justify-content-start mt-4">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Rebuy Points
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="rebuyPoint"
                                    className="form-control-input"
                                    value={formValue.rebuyPoint}
                                    min={0}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isRebuyTicket && (
                            <>
                              <div className="form-group flex justify-content-start mt-4">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Rebuy Tickets
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="rebuyTicket"
                                    className="form-control-input"
                                    min={0}
                                    value={formValue.rebuyTicket}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {/* SELECT REENTRY */}
                  <div className="select-reentry form-group-last pb-4">
                    <div className="form-group flex justify-content-start mt-4">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Select Reentry
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          disabled={true}
                          name="isReentryAllowed"
                          className="form-control-input"
                          value={formValue.isReentryAllowed}
                        >
                          <option hidden>Is Reentry Allowed</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    {selectPrice.isReenTryPrice && (
                      <>
                        <div className="form-group flex justify-content-start mt-4">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Reentry Chips
                          </label>
                          <div className="col-md-12 col-lg-8 col-12">
                            <input
                              disabled
                              type="number"
                              name="reentryChip"
                              placeholder="Reentry Chips"
                              value={formValue.reentryChip}
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                        </div>
                        <div className="form-group flex justify-content-start mt-4">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            No of Reentry
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              disabled
                              type="number"
                              name="numberOfReentry"
                              placeholder="No of Reentry"
                              className="form-control-input"
                              value={formValue.numberOfReentry}
                              min={0}
                            />
                          </div>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                          >
                            Last Reentry(Blind Level)
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                            <input
                              disabled
                              type="number"
                              name="lastReentry"
                              value={formValue.lastReentry}
                              className="form-control-input"
                              placeholder="Last Reentry(Blind Level)"
                              min={0}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {selectPrice.isReenTryPrice && (
                      <>
                        <div className="reentry_Price">
                          <div className="form-group flex justify-content-start mt-4">
                            <label
                              htmlFor=""
                              className="col-md-12 col-lg-12 col-xl-2 col-12"
                            >
                              Reentry Price
                            </label>
                            <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                              <Form.Check
                                type="switch"
                                value="true"
                                id="reentryRealChip"
                                disabled
                                label="Real chips"
                                name="reentryRealChip"
                                defaultChecked={formValue.reentryRealChip}
                              />
                            </div>
                            {/* <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                              <Form.Check
                                type="switch"
                                id="reentryVipPoint"
                                value="true"
                                label="VIP Points"
                                name="reentryVipPoint"
                                defaultChecked={formValue.reentryVipPoint}
                              />
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                              <Form.Check
                                type="switch"
                                id="isReentryTicket"
                                label="Tickets"
                                value="true"
                                name="isReentryTicket"
                                defaultChecked={formValue.isReentryTicket}
                              />
                            </div> */}
                          </div>
                          {selectPrice.isReenTryRealChip && (
                            <>
                              <div className="form-group flex justify-content-start mt-4">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Reentry Amount
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="reentryAmount"
                                    value={formValue.reentryAmount}
                                    className="form-control-input"
                                    min={0}
                                  />
                                </div>
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                                >
                                  House Fees(Reentry)
                                </label>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3">
                                  <input
                                    disabled
                                    type="number"
                                    name="reentryHouseFee"
                                    className="form-control-input"
                                    min={0}
                                    value={formValue.reentryHouseFee}
                                  />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="reentryHouseFeeTotal"
                                    value={
                                      Number(formValue.reentryAmount) +
                                      Number(formValue.reentryHouseFee)
                                    }
                                    placeholder="Total Chips"
                                    className="form-control-input"
                                    min={0}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isReenTryVipPoint && (
                            <>
                              <div className="form-group flex justify-content-start mt-4">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Reentry Points
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="reentryPoint"
                                    value={formValue.reentryPoint}
                                    className="form-control-input"
                                    min={0}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {selectPrice.isReenTryTicket && (
                            <>
                              <div className="form-group flex justify-content-start mt-4">
                                <label
                                  htmlFor=""
                                  className="col-md-12 col-lg-12 col-xl-2 col-12"
                                >
                                  Reentry Tickets
                                </label>
                                <div className="col-md-12 col-lg-8 col-12">
                                  <input
                                    disabled
                                    type="number"
                                    name="reentryTicket"
                                    className="form-control-input"
                                    value={formValue.reentryTicket}
                                    min={0}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {/* SELECT ADDON */}
                  <div className="select-addon form-group-last pb-4">
                    <div className="form-group flex justify-content-start mt-4">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Select Addon
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          disabled={true}
                          name="isAddonTimeEnabled"
                          className="form-control-input"
                          value={formValue.isAddonTimeEnabled}
                        >
                          <option hidden>Is Addon Allowed</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                    {selectPrice.isSelectAddon && (
                      <>
                        <div className="form-group flex justify-content-start mt-4">
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            No of AddOn
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                            <input
                              disabled
                              type="number"
                              name="numberOfAddOn"
                              placeholder="No of Addon"
                              value={listCountAddOn}
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                          >
                            Add On Price
                          </label>
                          <div className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3">
                            <input
                              disabled
                              type="number"
                              name="addOnPrice"
                              value={formValue.addOnPrice}
                              placeholder="Add On Price"
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                          <div className="col-md-12 col-lg-12 col-xl-2 col-12">
                            <input
                              disabled
                              type="number"
                              name="addOnChip"
                              value={formValue.addOnChip}
                              placeholder="Addon chips"
                              className="form-control-input"
                              min={0}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {selectPrice.isSelectAddon &&
                      formValue.addOnTime?.map((data: any, index: any) => (
                        <div
                          key={index}
                          className="form-group flex justify-content-start mt-4"
                        >
                          <label
                            htmlFor=""
                            className="col-md-12 col-lg-12 col-xl-2 col-12"
                          >
                            Add On Time(Blind Level)
                          </label>
                          <div className="col-md-12 col-lg-8 col-12">
                            <input
                              disabled
                              type="number"
                              name={"blindLevel" + index}
                              className="form-control-input"
                              value={data}
                              min={0}
                              placeholder="Add On Time(Blind Level)"
                            />
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* PAYOUT */}
                  <div className="payout">
                    <div className="form-group flex justify-content-start mt-4">
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12"
                      >
                        Payout Type
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          disabled={true}
                          name="payoutType"
                          className="form-control-input"
                          value={formValue.payoutType}
                        >
                          <option hidden>Select Payout Type</option>
                          {PayoutType?.map((data, index) => (
                            <option key={index} value={data.value}>
                              {data.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <label
                        htmlFor=""
                        className="col-md-12 col-lg-12 col-xl-2 col-12 mx-3"
                      >
                        Select Payout
                      </label>
                      <div className="col-md-12 col-lg-12 col-xl-3 col-12">
                        <select
                          disabled={true}
                          name="payoutId"
                          className="form-control-input"
                          value={formValue.payoutId}
                        >
                          <option hidden>Select Payout</option>
                          {dataPayout?.map((data: any, index: any) => (
                            <option key={index} value={data._id}>
                              {data.payoutName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <Link to="/listTournament">
                    <button
                      type="button"
                      className="btn-custom btn-secondary mx-2 mb-5 my-5"
                    >
                      Back
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </TabContainer>
  );
};

export default ViewTournament;
