import Config from "../../config/config";

const CryptoJS = require("crypto-js");

export let encrypt = async (data: any) => {
  const encryptData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    Config.encryptionKey
  ).toString();
  return encryptData;
};

export let decrypt = async (data: any) => {
  const bytes = CryptoJS.AES.decrypt(data, Config.encryptionKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
