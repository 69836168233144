import Config from "../config/config";
import axiosInstance from "./axiosInstance";

let url: string = "tournament";

const create = (data: any) => {
  return axiosInstance.post(Config.apiUrl + url, data);
};
const listTournament = (data: any) => {
  return axiosInstance.post(Config.apiUrl + url + "/listTournament", data);
};
const getAllName = () => {
  return axiosInstance.get(Config.apiUrl + url + "/getAllName");
};
const getAll = () => {
  return axiosInstance.get(Config.apiUrl + url);
};

const getAllPagination = (data: any, page: number = 1, limit: number = 10) => {
  return axiosInstance.post(
    Config.apiUrl + url + `/getAll?page=${page}&limit=${limit}`,
    data
  );
};

const update = (data: any, id: string | undefined) => {
  return axiosInstance.post(Config.apiUrl + url + "/" + id, data);
};
const getOne = (id: string | undefined) => {
  return axiosInstance.get(Config.apiUrl + url + "/" + id);
};
const getOneNotNested = (id: string | undefined) => {
  return axiosInstance.get(Config.apiUrl + url + "/notNested/" + id);
};
const deleteOne = (id: string) => {
  return axiosInstance.delete(Config.apiUrl + url + "/" + id);
};

const cancel = (id: string) => {
  return axiosInstance.post(Config.apiUrl + url + "/cancel", { id: id });
};
const filter = (data: any, page: number = 1, limit: number = 10) => {
  return axiosInstance.post(
    Config.apiUrl + url + `/filter?page=${page}&limit=${limit}`,
    data
  );
};

const changeStatus = (id: string | any) => {
  return axiosInstance.post(Config.apiUrl + url + "/changeStatus/" + id);
};

const getOneTournamentFromRedis = (id: string | any) => {
  return axiosInstance.post(
    Config.apiUrl + url + "/getOneTournamentFromRedis/" + id
  );
};

const getOneTournamentFromClosed = (id: string | any) => {
  return axiosInstance.post(
    Config.apiUrl + url + "/getOneTournamentFromClosed/" + id
  );
};

const tournamentServices = {
  create,
  getAll,
  getOneNotNested,
  getOne,
  deleteOne,
  update,
  getAllName,
  filter,
  listTournament,
  getAllPagination,
  cancel,
  changeStatus,
  getOneTournamentFromRedis,
  getOneTournamentFromClosed,
};
export default tournamentServices;
