import { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, TabContainer } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import { encryptionEnable } from "../../../config/config";
import "./ListQuestionManagement.css";
import * as ulti from "../../../utils/decrypt/index";
import { useMutation, useQuery } from "@tanstack/react-query";
import GuessQuestion from "../../../services/GuessQuestion.services";
import { GrView } from "react-icons/gr";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";

const initFormValue = {
    filter: {
        questionSubject: "",
        question: "",
        questionAr: "",
        correctAnswer: "",
        difficultyLevel: "",
        guessQuestionId: ""
    },
};
const initialFormAdd = {
    questionSubject: "",
    question: "",
    questionAr: "",
    correctAnswer: 0,
    difficultyLevel: ""
};
const initialValueQuestion = {
    id: "",
    questionSubject: "",
    question: "",
    questionAr: "",
    correctAnswer: 0,
    difficultyLevel: ""
}
const initialAnswer = {
    ansE: "",
    ansA: ""
};
const resetData = {
    difficultyLevel: "",
    subjectType: "",
    isReported: "",
    isDisabled: ""
}
const PER_PAGE = 10;
const ListQuestionManagement = () => {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [listGuessQuestion, setListGuessQuestion] = useState<any>([])
    const [show, setShow] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formValue, setFormValue] = useState<any>(resetData);
    const [formAdd, setFormAdd] = useState<any>(initialFormAdd);
    const [formEdit, setFormEdit] = useState<any>(initialFormAdd);
    const [guessTion, setGuessTion] = useState<any>(initialValueQuestion)
    const [ansWer, setAnsWer] = useState<any>(initialAnswer);
    const [page, setPage] = useState<number>(0);
    const [search, setSearch] = useState<any>(initFormValue);
    const [formContent, setFormContent] = useState<any>([])
    const [formCSV, setFormCSV] = useState<any>([])
    const [id, setId] = useState<any>("")
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [arabic, setArabic] = useState<boolean>(false)
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm<any>({});

    const handleUpload = async () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleDelete = () => {
        setShowDelete(false)
        deleteOneGuesstion.mutate(id)
    }
    const handleDeleteAll = () => {
        Swal.fire({
            title: "Are you sure? You are going to delete all the questions.",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((res) => {
            if (res.isConfirmed) {
                deleteGuesstion.mutate();
            }
        });
    };
    const handleShowDelete = (id: any) => {
        setShowDelete(true);
        setId(id);
    }
    const handleCloseDelete = () => {
        setShowDelete(false)
    }
    const handleReset = () => {
        setPage(0);
        setFormValue(resetData)
    }
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            const formData = new FormData();
            formData.append('upFileGuessQuestion', selectedFile);
            PutFileXLSX.mutate(formData)
        }
    };

    const createGuesstion = useMutation((data: any) => GuessQuestion.createQuestion(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                setFormAdd(initialFormAdd);
                setAnsWer(initialAnswer)
                toast.success("Create Guesstion Management Successful")
                setShow(false);
                refetch();
            }
        },
        onError: () => {
            toast.error("Create Guesstion Management Failed");
        },
    });
    const deleteOneGuesstion = useMutation((id: any) => GuessQuestion.deleteOneGuesstion(id), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Delete Guesstion Management Successful")
                setPage(0)
                refetch()
                setShowDelete(false)
            }
        },
        onError: () => {
            toast.error("Delete Guesstion Management Failed");
        },
    });
    const deleteGuesstion = useMutation(() => GuessQuestion.deleteGuesstion(), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Delete Guesstion Management Successful")
                setPage(0)
                refetch()
                setShowDelete(false)
            }
        },
        onError: () => {
            toast.error("Delete Guesstion Management Failed");
        },
    });
    const PutFileXLSX = useMutation((data: any) => GuessQuestion.putFileXLSX(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Import File Successful");
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                refetch();
            }
        },
        onError: () => {
            toast.error("Import File Failed");
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        },
    })
    const EditGuesstion = useMutation((data: any) => GuessQuestion.EditGuesstion(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                setAnsWer(initialAnswer)
                toast.success("Edit Guesstion Management Successful");
                setShowView(false);
                refetch();
            }
        },
        onError: () => {
            toast.error("Edit Guesstion Management Failed");
        },
    })
    const getOneGuesstion = useMutation((id: any) => GuessQuestion.getOneGuesstion(String(id)), {
        onSuccess: (data: any) => {
            if (data.data.status) {
                setShowView(true)
                setGuessTion(data.data.data[0]);
            }
        },
        onError: () => {
            toast.error("View Guess Question Failed");
        },
    });

    const { isLoading, refetch } = useQuery({
        queryKey: ["GET_LIST_GUESS_QUESTION", page, search],
        queryFn: (_) => {
            return GuessQuestion.getListGuessTion({ page: page + 1, ...search });
        },
        onSuccess: (data) => {
            if (data?.data?.status) {
                setListGuessQuestion(data?.data?.data);
                toast.success("Get List Gueess Question Successful");
                setTotalCount(data?.data?.count);
                getListExportData.mutate(search);
            }
        },
        onError: (err: any) => {
            const { response } = err;
            if (!response.data) {
                toast.error("Connect to server failed.");
            } else {
                toast.error("No Item Found.");
            }
        },
        retry: 1,
    });

    const getListExportData = useMutation((search: any) => GuessQuestion.getListExportData(search), {
        onSuccess: (data: any) => {
            if (data.data.status) {
                setFormContent(data.data.data)
            }
        },
        onError: () => {
            toast.error("View Guess Question Failed");
        },
    })

    const { data: listSubjectType } = useQuery({
        queryKey: ["GET_LIST_SUBJECTTYPE"],
        queryFn: (_) => {
            return GuessQuestion.getListSubjectType();
        },
        onError: (err: any) => {
            const { response } = err;
            if (!response.data) {
                toast.error("Connect to server failed.");
            } else {
                toast.error("No Item Found.");
            }
        },
        retry: 1,
    });
    const handleGetSubjectName = (subjectId: string) => {
        let subjectName: string = ""
        listSubjectType?.data?.data?.filter((val: any) => {
            if (val.questionSubjectId === subjectId) {
                subjectName = val.name
            }
        })
        return subjectName
    }

    const onInputAddChange = (e: any) => {
        let { value, name } = e.target;
        setFormAdd({ ...formAdd, [name]: value });
    }
    const onAnswerChange = (e: any) => {
        let { value, name } = e.target;
        setAnsWer({ ...ansWer, [name]: value });
    }
    const onInputEditChange = (e: any) => {
        let { value, name } = e.target;
        setFormEdit({ ...formAdd, [name]: value });
    }

    const handleAdd = () => {
        const updatedFormAdd = { ...formAdd, correctAnswer: +ansWer.ansA };
        createGuesstion.mutate(updatedFormAdd);
    }
    const handleEdit = () => {
        const data = {
            id: guessTion?._id,
            questionSubject: formEdit?.questionSubject ? formEdit?.questionSubject : guessTion?.questionSubject,
            question: formEdit?.question ? formEdit?.question : guessTion?.question,
            questionAr: formEdit?.questionAr ? formEdit?.questionAr : guessTion?.questionAr,
            correctAnswer: ansWer?.ansA ? +ansWer.ansA : +guessTion?.correctAnswer,
            difficultyLevel: formEdit?.difficultyLevel ? formEdit?.difficultyLevel : guessTion?.difficultyLevel,
        }
        EditGuesstion.mutate(data)
    }
    const handleClose = () => {
        setFormAdd(initialFormAdd);
        setAnsWer(initialAnswer)
        setShow(false)
    }
    const handleEditClose = () => {
        setShowView(false)
    }
    const handleCSV = () => {
        let content = formContent?.map((data: any) => {
            return {
                "Subject Type": handleGetSubjectName(data?.questionsubjectField[0]?.questionSubjectId),
                "Question ID": data?.guessQuestionId,
                "Question": data.question,
                "Answer": data.correctAnswer,
                "Difficulty": data?.difficultyLevel,
                "Report": data?.reportQuestionData.length > 0 ? "Yes" : "No",
                "Disable": data?.isDisabled ? "Yes" : "No",
            }
        })
        setFormCSV(content);
    };
    const exportToExcel = () => {
        const selectedFields = ['question', 'questionAr', 'correctAnswer', 'difficultyLevel', 'guessQuestionId']
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        formContent?.forEach((sheetData: any, index: number) => {
            const filteredData = sheetData?.data.map((row: any) => {
                return selectedFields.map((field: any) => {
                    if (Array.isArray(row[field])) {
                        return row[field].join(' ; ');
                    }
                    return row[field];
                });
            });
            const headerRow = selectedFields;
            const excelData = [headerRow, ...filteredData];
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
            XLSX.utils.book_append_sheet(wb, ws, `GQ ${sheetData._id}`);
        });
        const excelBlob = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob: Blob = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = 'ListGuessQuestion.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };
    const csvReport = {
        data: formCSV,
        filename: "ListGuessQuestion.csv",
    };
    const handlePageChange = (e: { selected: number }) => {
        const { selected } = e;
        setPage(selected);
    };
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormValue({ ...formValue, [name]: value })
    }
    const EditGuess = useMutation((data: any) => GuessQuestion.EditGuesstion(data), {
        onSuccess: (data: any) => {
            if (data.data.status) {
                toast.success("Edit Guess Question Management Successful");
                refetch();
            }
        },
        onError: () => {
            toast.error("Edit Guess Question Management Failed");
        },
    })
    const handleEditMCQ = (data: any) => {
        const val = {
            id: data?._id,
            isDisabled: !data?.isDisabled,
        }
        EditGuess.mutate(val)
    }
    const onSubmit = () => {
        const formData = {
            filter: {
                questionSubject: formValue?.subjectType ? formValue?.subjectType : "",
                difficultyLevel: formValue?.difficultyLevel ? formValue?.difficultyLevel : "",
                isReported: formValue?.isReported ? formValue?.isReported : "",
                isDisabled: formValue?.isDisabled === "Yes" ? true : false,
                question: "",
                questionAr: "",
                correctAnswer: "",
                guessQuestionId: ""
            }
        }
        setSearch(formData);
        setPage(0)
    }
    const totalPage = Math.ceil(totalCount / PER_PAGE);
    const displayData = listGuessQuestion?.map((data: any, index: any) => {

        return (
            <tr key={index} className="trTournament">
                <td>{index + PER_PAGE * page + 1}</td>
                <td>{handleGetSubjectName(data?.questionsubjectField[0]?.questionSubjectId)}</td>
                <td>{arabic ? data.questionAr : data.question}</td>
                <td>{data.correctAnswer}</td>
                <td>{data?.difficultyLevel}</td>
                <td> {data?.reportQuestionData.length > 0 ?
                    <div style={{ display: "flex", alignItems: "center", width: "100px", justifyContent: "center" }}>
                        <span style={{ fontSize: "1.4rem", marginRight: "5px" }}>Yes</span>
                        {/* Yes */}
                        <Link to={`/viewGuesstion/${data._id}`}>
                            <Button variant="outline-none">
                                <GrView />
                            </Button>
                        </Link>
                    </div>
                    : <p style={{ fontSize: "1.4rem" }}>No</p>

                }
                </td>
                <td>{data?.isDisabled ? "Yes" : "No"}</td>
                <td>
                    <div className="d-flex flex-nowrap gap-1 align-items-center">
                        <Button className=" action-icon" variant="outline-info" onClick={() => getOneGuesstion.mutate(data._id)}>
                            Edit
                        </Button>
                        <Button className=" action-icon" variant="outline-info" onClick={() => handleEditMCQ(data)}>
                            {data?.isDisabled ? "Active" : "Disable"}
                        </Button>
                        <Button className=" action-icon" variant="outline-info" onClick={() => handleShowDelete(data._id)}>
                            Delete
                        </Button>
                    </div>
                </td>
            </tr>
        );
    });

    return (
        <>
            <TabContainer>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-5">
                        <Col>
                            <BreadCrumbs childItem="List Guess Question Management" />
                            <div className="card m-auto">
                                <div className="portlet-title">
                                    <h3 className="page-title">Guess Question Management</h3>
                                </div>
                                <div className="search-form mt-5">
                                    <div className="form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="justify-content-center flex-wrap">
                                                <div className="flex-custom">
                                                    <div className=" col-3 col-md-3 col-lg-6 col-3-custom mx-2 my-2">
                                                        <select
                                                            className="form-control"
                                                            {...register("subjectType")}
                                                            name="subjectType"
                                                            onChange={handleChange}
                                                            value={formValue?.subjectType}
                                                        >
                                                            <option hidden>Subject Type</option>
                                                            {listSubjectType?.data.data?.map((data: any, index: any) => (
                                                                <option key={index} value={data._id}>
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className=" col-3 col-md-3 mx-2 my-2 col-lg-6 col-3-custom">
                                                        <select
                                                            className="form-control"
                                                            {...register("difficultyLevel")}
                                                            name="difficultyLevel"
                                                            value={formValue.difficultyLevel}
                                                            onChange={handleChange}
                                                        >
                                                            <option hidden>Diffculty</option>
                                                            <option value="Easy">
                                                                Easy
                                                            </option>
                                                            <option value="Medium">
                                                                Medium
                                                            </option>
                                                            <option value="Hard">
                                                                Hard
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="mx-10 my-2">
                                                        {arabic ?
                                                            <button
                                                                type="button"
                                                                className="btn-custom btn-edit btn-margin"
                                                                onClick={() => setArabic(!arabic)}
                                                            >
                                                                Arabic
                                                            </button> :
                                                            <button
                                                                type="button"
                                                                className="btn-custom btn-edit btn-margin"
                                                                onClick={() => setArabic(!arabic)}
                                                            >
                                                                English
                                                            </button>
                                                        }
                                                        <button
                                                            type="submit"
                                                            className="btn-custom btn-edit btn-margin"
                                                        // onClick={handleSearch}
                                                        >
                                                            Search
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn-custom btn-edit btn-margin"
                                                            onClick={handleReset}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn-custom btn-edit btn-margin"
                                                            onClick={handleDeleteAll}
                                                        >
                                                            Delete All
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="flex-custom mt-4" >
                                                    <div className="col-3 col-md-3 mx-2 my-2 col-lg-6 col-3-custom">
                                                        <select
                                                            className="form-control"
                                                            {...register("isReported")}
                                                            value={formValue.isReported}
                                                            name="isReported"
                                                            onChange={handleChange}

                                                        >
                                                            <option hidden>Is Reported</option>
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-3 mx-2 my-2 col-lg-6 col-3-custom">
                                                        <select
                                                            className="form-control"
                                                            {...register("state")}
                                                            value={formValue.isDisabled}
                                                            name="isDisabled"
                                                            onChange={handleChange}

                                                        >
                                                            <option hidden>Is Disabled</option>
                                                            <option value={"Yes"} >Yes</option>
                                                            <option value={"No"} >No</option>
                                                        </select>
                                                    </div>
                                                    <div className="mx-10 my-2">
                                                        <button
                                                            type="button"
                                                            className="btn-custom btn-edit btn-margin"
                                                            onClick={() => setShow(true)}
                                                        >
                                                            Add Question
                                                        </button>
                                                        <input type="file" accept=".xlsx" onChange={handleFileChange} style={{ display: "none" }} ref={fileInputRef} />
                                                        <button type="button" className="btn-custom btn-edit btn-margin" onClick={handleUpload}>Import CSV</button>

                                                        <button
                                                            type="button"
                                                            onClick={exportToExcel}
                                                            className="btn-custom btn-edit btn-margin"
                                                        >
                                                            Export to CSV
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="table-data ">
                                    <table className="table table-bordered mt-5">
                                        <thead>
                                            <tr className="trTournament">
                                                <th>S.No.</th>
                                                <th>Subject Type</th>
                                                {arabic ?
                                                    <>
                                                        <th>Question Ar</th>
                                                        <th>Answer</th>
                                                    </>
                                                    : <>
                                                        <th>Question</th>
                                                        <th>Answer</th>
                                                    </>
                                                }
                                                <th>Difficulty</th>
                                                <th>Report</th>
                                                <th>Disable</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{displayData}</tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            forcePage={page}
                            pageCount={totalPage}
                            onPageChange={handlePageChange}
                            activeClassName={"page-item active"}
                            disabledClassName={"page-item disabled"}
                            containerClassName={"pagination"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            pageLinkClassName={"page-link"}
                        />
                    </div>
                )}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h2>Add Question</h2>
                        <div className="form mt-2">
                            <form className="" onSubmit={handleAdd}>
                                <div className="form-group flex justify-content-start align-items-start">
                                    <label
                                        htmlFor="questionSubject"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Subject Type
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <select
                                            className="form-control"
                                            name="questionSubject"
                                            value={formAdd.questionSubject}
                                            onChange={onInputAddChange}
                                        >
                                            <option hidden>Subject Type</option>
                                            {listSubjectType?.data.data?.map((data: any, index: any) => (
                                                <option key={index} value={data._id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="question"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Question En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="question"
                                            id="question"
                                            value={formAdd.question}
                                            placeholder="type questions here"
                                            type="text"
                                            onChange={onInputAddChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="questionAr"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Question Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="questionAr"
                                            id="questionAr"
                                            value={formAdd.questionAr}
                                            placeholder="type questions here"
                                            type="text"
                                            max={10000000}
                                            min={1}
                                            onChange={onInputAddChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="ansA"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Correct Answer
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="ansA"
                                            id="ansA"
                                            value={ansWer.ansA}
                                            placeholder="type answer here (only numbers)"
                                            type="number"
                                            max={10000000}
                                            min={1}
                                            onChange={onAnswerChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start align-items-start mt-4">
                                    <label
                                        htmlFor="difficultyLevel"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Difficulty
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <select
                                            className="form-control"
                                            name="difficultyLevel"
                                            onChange={onInputAddChange}
                                            value={formAdd.difficultyLevel}
                                        >
                                            <option hidden>Diffculty</option>
                                            <option value="Easy">
                                                Easy
                                            </option>
                                            <option value="Medium">
                                                Medium
                                            </option>
                                            <option value="Hard">
                                                Hard
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-custom btn-edit" onClick={handleClose}>
                            Close
                        </button>
                        <button className="btn-custom btn-edit" onClick={handleAdd}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showView} onHide={handleEditClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h2 style={{ textAlign: "center" }} className="mb-2">Edit Question</h2>
                        <div className="form mt-4">
                            <form className="" onSubmit={handleEdit}>
                                <div className="form-group flex justify-content-start align-items-start">
                                    <label
                                        htmlFor="questionSubject"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Subject Type
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <select
                                            className="form-control"
                                            name="questionSubject"
                                            value={formEdit.questionSubject ? formEdit.questionSubject : guessTion.questionSubject}
                                            onChange={onInputEditChange}
                                        >
                                            <option hidden>Subject Type</option>
                                            {listSubjectType?.data.data?.map((data: any, index: any) => (
                                                <option key={index} value={data._id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="question"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Question En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="question"
                                            id="question"
                                            value={formEdit.question ? formEdit.question : guessTion.question}
                                            placeholder="type questions here"
                                            type="text"
                                            max={10000000}
                                            min={1}
                                            onChange={onInputEditChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                {/* <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="ansE"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Answer En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="ansE"
                                            id="ansE"
                                            value={ansWer.ansE ? ansWer.ansE : guessTion.correctAnswer}
                                            placeholder="Enter Stage Index"
                                            type="number"
                                            onChange={onAnswerChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div> */}
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="questionAr"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Question Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="questionAr"
                                            id="questionAr"
                                            value={formEdit.questionAr ? formEdit.questionAr : guessTion.questionAr}
                                            placeholder="type questions here"
                                            type="text"
                                            max={10000000}
                                            min={1}
                                            onChange={onInputEditChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="ansA"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Correct Answer
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="ansA"
                                            id="ansA"
                                            value={ansWer.ansA ? ansWer.ansA : guessTion.correctAnswer}
                                            placeholder="type answer here (only numbers)"
                                            type="number"
                                            max={10000000}
                                            step="any"
                                            min={1}
                                            onChange={onAnswerChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start align-items-start mt-4">
                                    <label
                                        htmlFor="difficultyLevel"
                                        className="col-md-12 col-lg-12 col-xl-3 col-12"
                                    >
                                        Difficulty
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <select
                                            className="form-control"
                                            name="difficultyLevel"
                                            onChange={onInputEditChange}
                                            value={formEdit.difficultyLevel ? formEdit.difficultyLevel : guessTion.difficultyLevel}
                                        >
                                            <option hidden>Diffculty</option>
                                            <option value="Easy">
                                                Easy
                                            </option>
                                            <option value="Medium">
                                                Medium
                                            </option>
                                            <option value="Hard">
                                                Hard
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-custom btn-edit" onClick={handleEditClose}>
                            Close
                        </button>
                        <button className="btn-custom btn-edit" onClick={handleEdit}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Do you want to Delete ?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn-custom btn-secondary"
                            onClick={handleCloseDelete}
                        >
                            Close
                        </button>
                        <button className="btn-custom btn-info" onClick={handleDelete}>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* <Modal show={showPublished} onHide={handleClosePublished}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Do you want to publish ?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn-custom btn-secondary"
                            onClick={handleClosePublished}
                        >
                            Close
                        </button>
                        <button className="btn-custom btn-info" onClick={handlePublished}>
                            Publish
                        </button>
                    </Modal.Footer>
                </Modal> */}
            </TabContainer >
        </>
    );
};
export default ListQuestionManagement;
