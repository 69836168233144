import { Col, Modal, TabContainer, Accordion, Card, Button, Table } from "react-bootstrap";
import BreadCrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import "./tournamentdetail.css"
import { useEffect, useState } from "react";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Loading from "../../../../components/loading/Loading";
import { useParams } from "react-router-dom";
import { getDetailMiniTournament } from "../../../../services/MiniManagement.services";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import moment from "moment";
const TournamentDetail = () => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const { id } = useParams();
    const [tableData, setTableData] = useState<string[]>([])
    const [remainPlayer, setRemainPlayer] = useState<number>(0)
    const [closedTournament, setClosedTournament] = useState<any>()
    const [currentTournament, setCurrentTournament] = useState<any>()
    const [gameMatches, setGameMatches] = useState<any>()
    const toggleExpand = (index: number) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const { isLoading, refetch } = useQuery(["GET_TOURNAMENT_CONFIG"], () => getDetailMiniTournament(id ? id : ""), {
        onSuccess: (res) => {
            const { data, status } = res;
            if (status === 200) {
                setRemainPlayer(data?.gameMatches?.length)
                setGameMatches(data?.gamematchesField)
                setTableData(data.rounds)
                setClosedTournament(data?.closedTournament ? data?.closedTournament : []);
                setCurrentTournament(data?.currentTournament ? data?.currentTournament : [])
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
        },
    });
    const returnWinningPlayer = () => {
        // gameMatches?.map((val: any) => {
        //     val.players?.map((p: any) => {
        //         if (p.status === "won")
        //     })
        // })
    }

    return (
        <>
            <TabContainer>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem=" Mini Tournament Report"
                                parentItem=" Mini Tournament Report"
                                parentLink="reportTournament" />
                            <div className="card m-auto custom">
                                <div className="portlet-title">
                                    <h3 className="page-title">Current Mini Tournament</h3>
                                </div>
                                <div className="row px-4 my-4">
                                    <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                                        <div className="mb-4">
                                            <h2>Total No. Of Players: {closedTournament?.numberOfPlayers}</h2>
                                        </div>
                                        <div className="mb-4">
                                            <h2>Tournament Id: {closedTournament?.tournamentId} </h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-xs-12 px-4 mb-5">
                                        <div className="mb-4">
                                            <h2>Current Tournament Running Since: {moment(closedTournament?.creta).format("DD/MM/YYYY")} </h2>
                                        </div>
                                        <div className="mb-4">
                                            <h2>Remaining Players: {remainPlayer * 3} </h2>
                                        </div>
                                    </div>
                                </div>
                                <List>
                                    {tableData && tableData?.map((item: any, index: any) => (
                                        <div key={index} >
                                            <div className="flex">
                                                <ListItem onClick={() => toggleExpand(index)} >
                                                    {expanded[index] ? <AddCircleOutlineIcon className="icon" /> : <RemoveCircleOutlineOutlinedIcon className="icon" />}
                                                    <ListItemText
                                                        primary={"Round " + (index + 1)}
                                                        primaryTypographyProps={{ style: { fontSize: '18px', marginLeft: '5px' } }}
                                                    />
                                                </ListItem>
                                            </div>
                                            <Collapse in={expanded[index]} style={{ marginLeft: '20px', fontWeight: '500' }} >
                                                <Table bordered hover className="custom-width-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Game ID</th>
                                                            <th>Player Type</th>
                                                            <th>User Name</th>
                                                            <th>Status</th>
                                                            <th>Remaining Players</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            item?.gamematchesField?.map((val: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{val.matchId}</td>
                                                                        <td>{val.players.map((v: any, i: any) => {
                                                                            if (v.status === "won") {

                                                                                return v.playerType
                                                                            }
                                                                        })}</td>
                                                                        <td>{val.players.map((v: any, i: any) => {
                                                                            if (v.status === "won") {

                                                                                return v.username
                                                                            }
                                                                        })}</td>
                                                                        <td>{item?.status}</td>
                                                                        <td>{remainPlayer * item?.numberOfMatches}</td>
                                                                    </tr>

                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </Table>
                                            </Collapse>
                                        </div>
                                    ))}
                                </List>
                            </div>

                        </Col>
                    </div>
                )}
            </TabContainer>
        </>
    )
}
export default TournamentDetail