import classNames from "classnames/bind";
import styles from "./sidebar.module.css";
import { ImHome } from "react-icons/im";

import SidebarData from "./sidebar-data/SidebarData";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../utils/context/ThemeProvider";

const cx = classNames.bind(styles);
function Sidebar() {
  const listName = [
    // {
    //   id: 0,
    //   name: "Mini Tournament Management",
    //   isActive: false,
    //   children: [
    //     { name: "Mini Tournament ", destination: "minTournament" },
    //     // { name: "Rake Report", destination: "rakeReport" },
    //   ],
    // },
    // {
    //   id: 1,
    //   name: "Mini Tournament Report",
    //   isActive: false,
    //   children: [
    //     { name: "Mini Tournament Report", destination: "reportTournament" },
    //     // { name: "Mini Tournament Detail", destination: "detailTournament" },
    //   ],
    // },
    {
      id: 0,
      name: "Guess Question Management",
      isActive: false,
      children: [
        { name: "Guess Question Management ", destination: "questionManagement" },
        // { name: "Mini Tournament Detail", destination: "detailTournament" },
      ],
    },
    {
      id: 1,
      name: "MCQ Question Management",
      isActive: false,
      children: [
        { name: "MCQ Question Management ", destination: "mcqQuestionManagement" },
        // { name: "Mini Tournament Detail", destination: "detailTournament" },
      ],
    },
  ];
  const context = useContext(ThemeContext);
  return (
    <div className={cx("container")}>
      <ul
        className={cx(
          "sidebar-container",
          `${context?.isActiveMenu ? "active" : ""}`
        )}
      >
        <Link className={cx("link")} to="/dashboard">
          <li
            className={cx("dashboard", "wrapper")}
            onClick={() => context?.handleIsActiveMenu()}
          >
            <div className={cx("sidebar-icon")}>
              <ImHome />
              <span>Dashboard</span>
            </div>
          </li>
        </Link>

        {listName.map((menu, index) => (
          <div key={index}>
            <SidebarData
              id={menu.id}
              name={menu.name}
              children={menu.children}
              index={index}
            />
          </div>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
