import Config from "../config/config";
import axiosInstance from "./axiosInstance";

let url: string = "dashboard/api";

const create = (data: any) => {
  return axiosInstance.post(Config.apiUrl + url + "/login", data);
};

const forgotPassword = (data: any) => {
  return axiosInstance.post(Config.apiUrl + url + "/forgotPassword", data);
};
const AuthServices = {
  create,
  forgotPassword,
};

export default AuthServices;
