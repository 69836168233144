import { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, TabContainer } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import { encryptionEnable } from "../../../config/config";
import "./ListMCQQuestionManagement.css";
import * as ulti from "../../../utils/decrypt/index";
import { useMutation, useQuery } from "@tanstack/react-query";
import MCQQuestion from "../../../services/MCQQuestion.services";
import { GrView } from "react-icons/gr";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { useFieldArray, useForm } from "react-hook-form";
import * as XLSX from 'xlsx';
import React from "react";
import Swal from "sweetalert2";
const initFormValue = {
    filter: {
        questionSubject: "",
        question: "",
        questionAr: "",
        correctAnswer: "",
        correctAnswerAr: "",
        difficultyLevel: "",
        mcQuestionId: "",
        isReported: "",
        isDisabled: "",
    },
};
const initialFormAdd = {
    questionSubject: "",
    question: "",
    questionAr: "",
    correctAnswer: "",
    correctAnswerAr: "",
    answers: [],
    answersAr: [],
    difficultyLevel: ""
};
const initialValueQuestion = {
    answers: [],
    id: "",
    questionSubject: "",
    question: "",
    questionAr: "",
    correctAnswer: 0,
    difficultyLevel: ""
}
const initialAnswer = {
    ansE: "",
    ansA: ""
};
const resetData = {
    difficultyLevel: "",
    subjectType: "",
    isReported: "",
    isDisabled: ""
}
const PER_PAGE = 10;
const ListMCQQuestionManagement = () => {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [listGuessQuestion, setListGuessQuestion] = useState<any>([])
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formValue, setFormValue] = useState<any>(initFormValue);
    const [formAdd, setFormAdd] = useState<any>(initialFormAdd);
    const [page, setPage] = useState<number>(0);
    const [search, setSearch] = useState<any>(initFormValue);
    const [formContent, setFormContent] = useState<any>([])
    console.log("🚀 ~ file: ListMCQQuestionManagement.tsx:72 ~ ListMCQQuestionManagement ~ formContent:", formContent)
    const [formCSV, setFormCSV] = useState<any>([])
    const [id, setId] = useState<any>("")
    const [answerInputs, setAnswerInputs] = useState(["", "", "", ""]);
    const [answerAInputs, setAnswerAInputs] = useState(["", "", "", ""]);
    const [arabic, setArabic] = useState<any>(false)
    const exportToExcel = () => {
        const selectedFields = ['question', 'questionAr', 'answers', 'answersAr', 'correctAnswer', 'correctAnswerAr', 'difficultyLevel', 'mcQuestionId']
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        formContent?.forEach((sheetData: any, index: number) => {
            const filteredData = sheetData?.data.map((row: any) => {
                return selectedFields.map((field: any) => {
                    if (Array.isArray(row[field])) {
                        return row[field].join(' ; ');
                    }
                    return row[field];
                });
            });
            const headerRow = selectedFields;
            const excelData = [headerRow, ...filteredData];
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
            XLSX.utils.book_append_sheet(wb, ws, `MC ${sheetData._id}`);
        });
        const excelBlob = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob: Blob = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = 'ListMCQuestion.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const onAnswerEChange = (e: any, index: any) => {
        const { value } = e.target;
        const newAnswerInputs = [...answerInputs];
        newAnswerInputs[index] = value;
        console.log("🚀 ~ file: ListMCQQuestionManagement.tsx:82 ~ onAnswerEChange ~ newAnswerInputs:", newAnswerInputs)
        setAnswerInputs(newAnswerInputs);
    };
    const onAnswerAChange = (e: any, index: any) => {
        const { value } = e.target;
        const newAnswerInputs = [...answerAInputs];
        newAnswerInputs[index] = value;
        setAnswerAInputs(newAnswerInputs);
    };
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm<any>({});

    const handleUpload = async () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleDelete = () => {
        setShowDelete(false)
        deleteOneGuesstion.mutate(id)
    }
    const handleDeleteAll = () => {
        Swal.fire({
            title: "Are you sure? You are going to delete all the questions.",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((res) => {
            if (res.isConfirmed) {
                deleteGuesstion.mutate();
            }
        });
    };
    const handleShowDelete = (id: any) => {
        setShowDelete(true);
        setId(id);
    }
    const handleCloseDelete = () => {
        setShowDelete(false)
    }
    const handleReset = () => {
        setPage(0);
        setSearch(initFormValue)
        setFormValue(resetData)
    }
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            const formData = new FormData();
            formData.append('upFileMCQuestion', selectedFile);
            PutFileXLSX.mutate(formData)
        }
    };
    const createMCQ = useMutation((data: any) => MCQQuestion.createMCQ(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Create MCQ Question Successful")
                setShow(false);
                setFormAdd(initialFormAdd);
                setAnswerAInputs(["", "", "", ""]);
                setAnswerInputs(["", "", "", ""]);
                refetch();
            }
        },
        onError: () => {
            toast.error("Create MCQ Question Failed");
        },
    });
    const deleteOneGuesstion = useMutation((id: any) => MCQQuestion.deleteOneMCQ(id), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Delete Guesstion Management Successful")
                setPage(0)
                refetch()
                setShowDelete(false)
            }
        },
        onError: () => {
            toast.error("Delete Guesstion Management Failed");
        },
    });
    const deleteGuesstion = useMutation(() => MCQQuestion.deleteMCQ(), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Delete Guesstion Management Successful")
                setPage(0)
                refetch()
                setShowDelete(false)
            }
        },
        onError: () => {
            toast.error("Delete Guesstion Management Failed");
        },
    });
    console.log(formAdd, answerAInputs, answerInputs)
    const PutFileXLSX = useMutation((data: any) => MCQQuestion.putFileXLSX(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Import File Successful");
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                refetch();
            }
        },
        onError: () => {
            toast.error("Import File Failed");
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        },
    })
    const { isLoading, refetch } = useQuery({
        queryKey: ["GET_LIST_GUESS_QUESTION", page, search],
        queryFn: (_) => {
            return MCQQuestion.getListMCQ({ page: page + 1, ...search });
        },
        onSuccess: (data) => {
            if (data?.data?.status) {
                setListGuessQuestion(data?.data?.data);
                toast.success("Get List MCQ Question Successful");
                setTotalCount(data?.data?.count);
                getListExportData.mutate(search);
            }
        },
        onError: (err: any) => {
            const { response } = err;
            if (!response.data) {
                toast.error("Connect to server failed.");
            } else {
                toast.error("No Item Found.");
            }
        },
        retry: 1,
    });

    const getListExportData = useMutation((search: any) => MCQQuestion.getListExportData(search), {
        onSuccess: (data: any) => {
            if (data.data.status) {
                setFormContent(data.data.data)
            }
        },
        onError: () => {
            toast.error("View Guess Question Failed");
        },
    })

    const { data: listSubjectType } = useQuery({
        queryKey: ["GET_LIST_SUBJECTTYPE"],
        queryFn: (_) => {
            return MCQQuestion.getListSubjectType();
        },
        onError: (err: any) => {
            const { response } = err;
            if (!response.data) {
                toast.error("Connect to server failed.");
            } else {
                toast.error("No Item Found.");
            }
        },
        retry: 1,
    });
    const handleGetSubjectName = (subjectId: string) => {
        let subjectName: string = ""
        listSubjectType?.data?.data?.filter((val: any) => {
            if (val.questionSubjectId === subjectId) {
                subjectName = val.name
            }
        })
        return subjectName
    }
    const onInputAddChange = (e: any) => {
        let { value, name } = e.target;
        setFormAdd({ ...formAdd, [name]: value });
    }
    const EditMCQ = useMutation((data: any) => MCQQuestion.EditMCQ(data), {
        onSuccess: (data: any) => {
            if (data.data.status) {
                toast.success("Edit MCQ Question Management Successful");
                refetch();
            }
        },
        onError: () => {
            toast.error("Edit MCQ Question Management Failed");
        },
    })
    const handleEdit = (data: any) => {
        const val = {
            id: data?._id,
            isDisabled: !data?.isDisabled,
        }
        EditMCQ.mutate(val)
    }

    const handleAdd = () => {
        const data = {
            questionSubject: formAdd.questionSubject,
            question: formAdd.question,
            questionAr: formAdd.questionAr,
            correctAnswer: formAdd.correctAnswer,
            correctAnswerAr: formAdd.correctAnswerAr,
            answers: answerInputs,
            answersAr: answerAInputs,
            difficultyLevel: formAdd.difficultyLevel
        }
        const checkAnswer = answerInputs?.filter((val: any) => { return val === formAdd?.correctAnswer })
        const checkAnswerAr = answerAInputs?.filter((val: any) => { return val === formAdd?.correctAnswerAr })
        checkAnswer?.length > 0 && checkAnswerAr?.length > 0
            ? createMCQ.mutate(data)
            : toast.error("Please Fill Correct Answer")
    }

    const handleClose = () => {
        setShow(false);
        setAnswerAInputs(["", "", "", ""]);
        setAnswerInputs(["", "", "", ""]);
        setFormAdd(initialFormAdd)
    }
    const handleCSV = () => {
        let content = formContent?.map((data: any) => {
            return {
                "Subject Type": handleGetSubjectName(data?.questionsubjectField[0]?.questionSubjectId),
                "MCQuestion ID": data.mcQuestionId,
                "Question": data.question,
                "Answer": data.answers.map((val: any) => { return val + " " }),
                "Correct Answer": data.correctAnswer,
                "Difficulty": data?.difficultyLevel,
                "Report": data?.reportQuestionData.length > 0 ? "Yes" : "No",
                "Disable": data?.isDisabled ? "Yes" : "No",
            }
        })
        setFormCSV(content);
    };
    const csvReport = {
        data: formCSV,
        filename: "ListMCQQuestionManagement.csv",
    };
    const handlePageChange = (e: { selected: number }) => {
        const { selected } = e;
        setPage(selected);
    };
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormValue({ ...formValue, [name]: value })
    }
    const handleUpdateStatus = useMutation((data: any) => MCQQuestion.EditMCQ(data), {
        onSuccess: ({ data }) => {
            const { status } = data;
            if (status) {
                toast.success("Delete Guesstion Management Successful")
                setPage(0)
                refetch()
                setShowDelete(false)
            }
        },
        onError: () => {
            toast.error("Delete Guesstion Management Failed");
        },
    });

    const onSubmit = () => {
        const formData = {
            filter: {
                questionSubject: formValue?.subjectType ? formValue?.subjectType : "",
                difficultyLevel: formValue?.difficultyLevel ? formValue?.difficultyLevel : "",
                isReported: formValue?.isReported ? formValue?.isReported : "",
                isDisabled: formValue?.isDisabled === "Yes" ? true : false,
                question: "",
                questionAr: "",
                correctAnswer: "",
                correctAnswerAr: "",
                mcQuestionId: "",
            }
        }
        setSearch(formData);
        setPage(0)
    }

    const totalPage = Math.ceil(totalCount / PER_PAGE);
    const displayData = listGuessQuestion
        ?.map((data: any, index: any) => {
            const answersList = arabic ? data?.answersAr : data?.answers
            return (
                <tr key={index} className="trTournament">
                    <td>{index + PER_PAGE * page + 1}</td>
                    <td>{handleGetSubjectName(data?.questionsubjectField[0]?.questionSubjectId)}</td>
                    <td>{arabic ? data.questionAr : data.question}</td>
                    <td>
                        {answersList?.map((val: any, index: number) => (
                            <React.Fragment key={index}>
                                <>{val}</>
                                {index < answersList.length - 1 && ' ; '}
                            </React.Fragment>
                        ))}
                    </td>
                    <td>{arabic ? data.correctAnswerAr : data.correctAnswer}</td>
                    <td>{data?.difficultyLevel}</td>
                    <td> {data?.reportQuestionData.length > 0 ?
                        <div style={{ display: "flex", alignItems: "center", width: "100px", justifyContent: "center" }}>
                            <span style={{ fontSize: "1.4rem", marginRight: "5px" }}>Yes</span>
                            {/* Yes */}
                            <Link to={`/viewMCQ/${data._id}`}>
                                <Button variant="outline-none">
                                    <GrView />
                                </Button>
                            </Link>
                        </div>
                        : <p style={{ fontSize: "1.4rem" }}>No</p>

                    }
                    </td>
                    <td>{data?.isDisabled ? "Yes" : "No"}</td>
                    <td>
                        <div className="d-flex flex-nowrap gap-1 align-items-center">
                            <Link to={`/editMCQ/${data._id}`}>
                                <Button className=" action-icon" variant="outline-info" >
                                    Edit
                                </Button>
                            </Link>
                            <Button className=" action-icon" variant="outline-info" onClick={() => handleEdit(data)}>
                                {data?.isDisabled ? "Active" : "Disable"}
                            </Button>
                            <Button className=" action-icon" variant="outline-info" onClick={() => handleShowDelete(data._id)}>
                                Delete
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });

    return (
        <>
            <TabContainer>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="list-tournament pb-5">
                        {/* <div>
                            <button onClick={() => exportToExcel()}>Export to Excel</button>
                        </div> */}
                        <Col>
                            <BreadCrumbs childItem="List MCQ Question Management" />
                            <div className="card m-auto">
                                <div className="portlet-title">
                                    <h3 className="page-title">MCQ Question Management</h3>
                                </div>
                                <div className="search-form mt-5">
                                    <div className="form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="justify-content-center flex-wrap">
                                                <div className="flex-custom">
                                                    <div className=" col-3 col-md-3 col-lg-6 col-3-custom mx-2 my-2">
                                                        <select
                                                            className="form-control"
                                                            {...register("subjectType")}
                                                            name="subjectType"
                                                            onChange={handleChange}
                                                            value={formValue?.subjectType}
                                                        >
                                                            <option hidden>Subject Type</option>
                                                            {listSubjectType?.data.data?.map((data: any, index: any) => (
                                                                <option key={index} value={data._id}>
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className=" col-3 col-md-3 mx-2 my-2 col-lg-6 col-3-custom">
                                                        <select
                                                            className="form-control"
                                                            {...register("difficultyLevel")}
                                                            name="difficultyLevel"
                                                            value={formValue.difficultyLevel}
                                                            onChange={handleChange}
                                                        >
                                                            <option hidden>Diffculty</option>
                                                            <option value="Easy">
                                                                Easy
                                                            </option>
                                                            <option value="Medium">
                                                                Medium
                                                            </option>
                                                            <option value="Hard">
                                                                Hard
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="mx-10 my-2">
                                                        {arabic ?
                                                            <button
                                                                type="button"
                                                                className="btn-custom btn-edit btn-margin"
                                                                onClick={() => setArabic(!arabic)}
                                                            >
                                                                Arabic
                                                            </button> :
                                                            <button
                                                                type="button"
                                                                className="btn-custom btn-edit btn-margin"
                                                                onClick={() => setArabic(!arabic)}
                                                            >
                                                                English
                                                            </button>
                                                        }

                                                        <button
                                                            type="submit"
                                                            className="btn-custom btn-edit btn-margin"
                                                        // onClick={onSubmit}
                                                        >
                                                            Search
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn-custom btn-edit btn-margin"
                                                            onClick={handleReset}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn-custom btn-edit btn-margin"
                                                            onClick={handleDeleteAll}
                                                        >
                                                            Delete All
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="flex-custom mt-4" >
                                                    <div className="col-3 col-md-3 mx-2 my-2 col-lg-6 col-3-custom">
                                                        <select
                                                            className="form-control"
                                                            {...register("isReported")}
                                                            value={formValue.isReported}
                                                            name="isReported"
                                                            onChange={handleChange}

                                                        >
                                                            <option hidden>Is Reported</option>
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-3 mx-2 my-2 col-lg-6 col-3-custom">
                                                        <select
                                                            className="form-control"
                                                            {...register("state")}
                                                            // value={formValue.isReported}
                                                            name="isDisabled"
                                                            onChange={handleChange}

                                                        >
                                                            <option hidden>Is Disabled</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No" >No</option>

                                                        </select>
                                                    </div>
                                                    <div className="mx-10 my-2">
                                                        <button
                                                            type="button"
                                                            className="btn-custom btn-edit btn-margin"
                                                            onClick={() => setShow(true)}
                                                        >
                                                            Add MCQ Question
                                                        </button>
                                                        <input type="file" accept=".xlsx" onChange={handleFileChange} style={{ display: "none" }} ref={fileInputRef} />
                                                        <button type="button" className="btn-custom btn-edit btn-margin" onClick={handleUpload}>Import CSV</button>
                                                        <button
                                                            type="button"
                                                            onClick={exportToExcel}
                                                            className="btn-custom btn-edit btn-margin"
                                                        >
                                                            Export to CSV
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="table-data ">
                                    <table className="table table-bordered mt-5">
                                        <thead>
                                            <tr className="trTournament">
                                                <th>S.No.</th>
                                                <th>Subject Type</th>
                                                {arabic ?
                                                    <>
                                                        <th>Question Ar</th>
                                                        <th>Answer Ar</th>
                                                    </>
                                                    :
                                                    <>
                                                        <th>Question</th>
                                                        <th>Answer</th>
                                                    </>
                                                }
                                                <th>Correct Answer</th>
                                                <th>Difficulty</th>
                                                <th>Report</th>
                                                <th>Disable</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{displayData}</tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            forcePage={page}
                            pageCount={totalPage}
                            onPageChange={handlePageChange}
                            activeClassName={"page-item active"}
                            disabledClassName={"page-item disabled"}
                            containerClassName={"pagination"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            pageLinkClassName={"page-link"}
                        />
                    </div>
                )}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h2 style={{ textAlign: "center" }}>Add MCQ Question</h2>
                        <div className="form mt-2">
                            <form className="" onSubmit={handleSubmit(handleAdd)}>
                                <div className="form-group flex justify-content-start align-items-start">
                                    <label
                                        htmlFor="questionSubject"
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Subject Type
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <select
                                            className="form-control"
                                            name="questionSubject"
                                            value={formAdd.questionSubject}
                                            onChange={onInputAddChange}
                                        >
                                            <option hidden>Subject Type</option>
                                            {listSubjectType?.data.data?.map((data: any, index: any) => (
                                                <option key={index} value={data._id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="question"
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Question En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="question"
                                            id="question"
                                            value={formAdd.question}
                                            placeholder="type questions here"
                                            type="text"
                                            onChange={onInputAddChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`anse0`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="anse0"
                                            id={`anse0`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerEChange(e, 0)}
                                            className="form-control-input"
                                            value={answerInputs[0]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`anse1`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="anse1"
                                            id={`anse1`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerEChange(e, 1)}
                                            className="form-control-input"
                                            value={answerInputs[1]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`anse2`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="anse2"
                                            id={`anse2`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerEChange(e, 2)}
                                            className="form-control-input"
                                            value={answerInputs[2]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`anse3`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="anse3"
                                            id={`anse3`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerEChange(e, 3)}
                                            className="form-control-input"
                                            value={answerInputs[3]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="correctAnswer"
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Correct Answer En
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="correctAnswer"
                                            id="correctAnswer"
                                            placeholder="type answer here "
                                            type="text"
                                            onChange={onInputAddChange}
                                            className="form-control-input"
                                            value={formAdd.correctAnswer}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="questionAr"
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Question Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="questionAr"
                                            id="questionAr"
                                            value={formAdd.questionAr}
                                            placeholder="type questions here"
                                            type="text"
                                            onChange={onInputAddChange}
                                            className="form-control-input"
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`ansA0`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="anseAr0"
                                            id={`anseAr0`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerAChange(e, 0)}
                                            className="form-control-input"
                                            value={answerAInputs[0]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`ansA1`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="ansA1"
                                            id={`ansA1`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerAChange(e, 1)}
                                            className="form-control-input"
                                            value={answerAInputs[1]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`ansA2`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="ansA2"
                                            id={`ansA2`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerAChange(e, 2)}
                                            className="form-control-input"
                                            value={answerAInputs[2]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor={`ansA3`}
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Answer Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="ansA3"
                                            id={`ansA3`}
                                            placeholder="type answer here"
                                            type="text"
                                            onChange={(e) => onAnswerAChange(e, 3)}
                                            className="form-control-input"
                                            value={answerAInputs[3]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start mt-4 align-items-start">
                                    <label
                                        htmlFor="correctAnswerAr"
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Correct Answer Ar
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <input
                                            required
                                            name="correctAnswerAr"
                                            id="correctAnswerAr"
                                            placeholder="type answer here "
                                            type="text"
                                            onChange={onInputAddChange}
                                            className="form-control-input"
                                            value={formAdd.correctAnswerAr}
                                        />
                                    </div>
                                </div>
                                <div className="form-group flex justify-content-start align-items-start mt-4">
                                    <label
                                        htmlFor="difficultyLevel"
                                        className="col-md-12 col-lg-12 col-xl-4 col-12"
                                    >
                                        Difficulty
                                    </label>
                                    <div className="col-md-12 col-lg-12 col-xl-7 col-12">
                                        <select
                                            className="form-control"
                                            name="difficultyLevel"
                                            onChange={onInputAddChange}
                                            value={formAdd.difficultyLevel}
                                        >
                                            <option hidden>Diffculty</option>
                                            <option value="Easy">
                                                Easy
                                            </option>
                                            <option value="Medium">
                                                Medium
                                            </option>
                                            <option value="Hard">
                                                Hard
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-custom btn-edit" onClick={handleClose}>
                            Close
                        </button>
                        <button className="btn-custom btn-edit" onClick={handleAdd}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Do you want to Delete ?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn-custom btn-secondary"
                            onClick={handleCloseDelete}
                        >
                            Close
                        </button>
                        <button className="btn-custom btn-info" onClick={handleDelete}>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* <Modal show={showPublished} onHide={handleClosePublished}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Do you want to publish ?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn-custom btn-secondary"
                            onClick={handleClosePublished}
                        >
                            Close
                        </button>
                        <button className="btn-custom btn-info" onClick={handlePublished}>
                            Publish
                        </button>
                    </Modal.Footer>
                </Modal> */}
            </TabContainer >
        </>
    );
};
export default ListMCQQuestionManagement;
