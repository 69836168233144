import { useState } from "react";
import { FaChevronRight, FaLock, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthServices from "../../../services/Auth.services";
import { validationLogin } from "../../../utils/validation/validation";
import "./login.css";

const Login = () => {
  const Navigate = useNavigate();
  const [account, setAccount] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
    if (account.username) errors.username = "";
    if (account.password.length > 5) errors.password = "";
  };

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setErrors(validationLogin(account));
    LoginSubmit(account);
  };

  const LoginSubmit = (data: any) => {
    if (account.password.length >= 6) {
      AuthServices.create(data)
        .then((data: any) => {
          if (data.data.status === "Wrong password") {
            setErrors({
              ...errors,
              password: "Incorrect username or password",
            });
          } else {
            let token = data.data.data;
            localStorage.setItem("access_token", token);
            toast.success("Login Successfully!");
            window.location.href = "/dashboard";
          }
        })
        .catch((errors) => {
          if (account.username !== "" && account.password.length >= 8) {
            toast.error("Incorrect username or password.");
          }
        });
    }
  };

  const handleForgorPassWord = () => {
    Navigate("/forgotPassword");
  };
  return (
    <div className="login-page overflow-hidden">
      <div className="container-login container">
        <div className="screen">
          <div className="screen__content">
            <form className="login">
              <div className="login__field col-12">
                <FaUser className="login__icon" />
                <input
                  type="text"
                  className="login__input"
                  placeholder="User name / Email"
                  name="username"
                  value={account.username}
                  onChange={handleChange}
                />
                {errors.username && <p className="error">{errors.username}</p>}
              </div>
              <div className="login__field">
                <FaLock className="login__icon" />
                <input
                  type="password"
                  className="login__input"
                  placeholder="Password"
                  name="password"
                  value={account.password}
                  onChange={handleChange}
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="forgot-password" onClick={handleForgorPassWord}>
                <h4>Forgot password?</h4>
              </div>
              <button
                className="button login__submit"
                onClick={handleFormSubmit}
              >
                <span className="button__text">Log In Now</span>
                <FaChevronRight className="button__icon" />
              </button>
            </form>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
